import React, {Suspense, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {Alert, ConfigProvider, Empty, notification, Radio, Row, Spin, Tabs, TabsProps, Typography} from 'antd';
import {
    PageContainer,
    ProForm,
    ProFormDateRangePicker,
    ProFormInstance,
    ProFormRadio,
    ProFormSelect
} from '@ant-design/pro-components';
import enUSIntl from 'antd/es/locale/en_US';
import dayjs from 'dayjs';
import {useAtom} from 'jotai';
import axios, {CancelTokenSource} from 'axios';
import axiosClient from '../api/axiosClient';
import InternalDonutTabContent from './InternalDonutTabContent';
import {each, groupBy} from '@antv/util';
import ExtensiveDonutData from './ExtensiveDonutData';
import {GlobalStateContext} from '../context/GlobalContext';
import {
    callingTunesDonutPropsAtom,
    dateRangeAtom,
    defaultFiltersAtom,
    donutPropsAtom,
    filtersAtom,
    formRefAtom,
    invoicesDonutPropsAtom,
    isInvoiceTableEnabledAtom,
    submittedFiltersAtom
} from '../atoms/internalDashboardAtoms';
import useCurrencyMap from '../query_hooks/useCurrencyMap';
import ColumnGraph from './DashboardComponents/ColumnGraph';
import BrandsInvoicesList from './DashboardComponents/InvoiceTable';
import qs from 'qs';
import InvoiceDonutChart from './DashboardComponents/InvoiceDonutChart';
import TotalRevenuesPieMemo from './DashboardComponents/TotalRevenuesPie';
import DashboardLoading from './DashboardLoading';
import {FiltersType} from "../atoms/externalDashboardAtoms";

// Tabs
export type TabsType = {
    name: string;
    metrics: string[];
    dimensions: string[];
    customer_id: string;
};
// Channel
export type RevenuesByChannelDataType = {
    asset: string;
    revenue: number;
};
export type RevenuesByChannelType = {
    currency: number;
    data: RevenuesByChannelDataType[];
    total: number;
};
// Label
export type RevenuesByLabelDataType = {
    asset: string;
    revenue: number;
};
export type RevenuesByLabelType = {
    currency: number;
    data: RevenuesByLabelDataType[];
    total: number;
};
// Product
export type RevenuesByProductDataType = {
    product: string;
    revenue: number;
};
export type RevenuesByProductType = {
    currency: number;
    data: RevenuesByProductDataType[];
    total: number;
};
// Owner
export type RevenuesByOwnerDataType = {
    owner: string;
    revenue: number;
};
export type RevenuesByOwnerType = {
    currency: number;
    data: RevenuesByOwnerDataType[];
    total: number;
};
// Invoices by Project
export type InvoicesByProjectDataType = {
    project_name: string;
    revenue: number;
};
export type InvoicesByProjectType = {
    currency: number;
    data: InvoicesByProjectDataType[];
    total: number;
};

// Invoices By Customer
export type InvoicesByCustomerDataType = {
    customer_name: string;
    revenue: number;
};

export type InvoicesByCustomerType = {
    currency: number;
    data: InvoicesByCustomerDataType[];
    total: number;
};

// CallingTunes By Customer
export type CallingTunesByCustomerDataType = {
    customer_name: string;
    revenue: number;
};

export type CallingTunesByCustomerType = {
    currency: number;
    data: CallingTunesByCustomerDataType[];
    total: number;
};

// DataSource
export type DataSourceType = {
    tabs: TabsType[];
    totalOwnedRevenuesByChannel: RevenuesByChannelType;
    totalOwnedRevenuesByLabel: RevenuesByLabelType;
    totalOwnedRevenuesByProduct: RevenuesByProductType;
    totalRevenuesByOwner: RevenuesByOwnerType;
    totalInvoicesByProject: InvoicesByProjectType;
    totalInvoicesByCustomer: InvoicesByCustomerType;
    totalCallingTunesByCustomer: CallingTunesByCustomerType;
    totalMusicPublishingByCustomer: CallingTunesByCustomerType;
};

// DataSource
export type PlatformMetrics = {
    metric: string;
    dimension: string;
};

export type DimensionType = {
    title: string;
    value: string;
};

type Group = 'IS' | 'ES' | 'IC' | 'EC';
type Metric = 'Dm' | 'Customer';
type ColumnTabsType =
    | 'totalRevenuesByOwner'
    | 'totalOwnedRevenuesByLabel'
    | 'totalOwnedRevenuesByChannel'
    | 'totalOwnedRevenuesByProduct'
    | 'totalInvoicesByProject'
    | 'totalInvoicesByCustomer'
    | 'totalCallingTunesByCustomer'
    | 'totalMusicPublishingByCustomer';

type PieTabsType =
    | 'YouTube (Labels)'
    | 'YouTube (Channels)'
    | 'Spotify'
    | 'Apple Music'
    | 'Amazon Premium'
    | 'Deezer'
    | 'Calling Tunes';

//
// FUNCTIONS TO FETCH ALL TAGS FOR THE SELECTION FILTERS
//

async function getCountryTagsForInternalDashboard() {
    return axiosClient.portal
        .get('/api/tags/country')
        .then((res) => ({
            label: 'Country',
            options: res.data
                ? res.data.map(({label, value}: any) => ({
                    label: label,
                    value: value
                }))
                : []
        }))
        .catch((e) => {
            console.error(e);
            return [];
        });
}

async function getPrimaryAccountManagerTagsForInternalDashboard() {
    return axiosClient.portal
        .get('/api/tags/account_manager_primary')
        .then((res) => ({
            label: 'Account Manager (Primary)',
            options: res.data
                ? res.data.map(({label, value}: any) => ({
                    label: label,
                    value: value
                }))
                : []
        }))
        .catch((e) => {
            console.error(e);
            return [];
        });
}

async function getTierTagsForInternalDashboard() {
    return axiosClient.portal
        .get('/api/tags/tier')
        .then((res) => ({
            label: 'Tier',
            options: res.data
                ? res.data.map(({label, value}: any) => ({
                    label: `Tier ${label}`,
                    value: value
                }))
                : []
        }))
        .catch((e) => {
            console.error(e);
            return [];
        });
}

//
// FUNCTIONS TO FETCH ALL BUYERS/SUPPLIERS FOR THE SELECTION FILTERS
//

async function getInternalSuppliersPromise() {
    return axiosClient.portal
        .post('/api/employees/getmanagedcustomers')
        .then((res) => ({
            label: 'Internal Suppliers',
            options: res.data
                ? res.data.map(({customer_id, customer_name}: any) => ({
                    label: `IS${customer_id} / ${customer_name}`,
                    value: `IS::${customer_id}`
                }))
                : []
        }))
        .catch((e) => {
            console.error(e);
            return [];
        });
}

async function getExternalSuppliersPromise() {
    return axiosClient.brands
        .get('/api/suppliers/')
        .then(({data}) => ({
            label: 'External Suppliers',
            options: data.map((datum: any) => ({
                label: `ES${datum.id} / ${datum.name}`,
                value: `ES::${datum.id}`
            }))
        }))
        .catch((e) => {
            console.error(e);
            return [];
        });
}

async function getInternalCustomersPromise() {
    return axiosClient.brands
        .get(`/api/accounting_customers/`)
        .then(({data}) => ({
            label: 'Internal Customers',
            options: data.map((datum: any) => ({
                label: `IC${datum.id} / ${datum.name}`,
                value: `IC::${datum.id}`
            }))
        }))
        .catch((e) => {
            console.error(e);
            return [];
        });
}

async function getExternalCustomersPromise() {
    return axiosClient.brands
        .get('/api/buyers/')
        .then(({data}) => ({
            label: 'External Customers',
            options: data.map((datum: any) => ({
                label: `EC${datum.id} / ${datum.name}`,
                value: `EC::${datum.id}`
            }))
        }))
        .catch((e) => {
            console.error(e);
            return [];
        });
}

function getCustomerArrayByGroup(values: Array<string>, filter: Group) {
    return values
        .map((cus) => cus.split('::'))
        .filter(([group, _]) => group === filter)
        .map(([_, value]) => +value);
}

const InternalDashboard = () => {
    const {TabPane} = Tabs;
    const {data: currencyMap} = useCurrencyMap();

    const currDate = new Date();
    //
    // Initialize State
    //
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [fetchingCustomers, setFetchingCustomers] = useState<boolean>(false);
    const [hasInvoices, setHasInvoices] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<DataSourceType>();
    const [donutChartMetrics, setDonutChartMetrics] = useState<Record<string, PlatformMetrics>>({});
    const [invoicesDonutChartDimensions, setInvoicesDonutChartDimensions] = useState<
        Record<string, DimensionType>
    >({'Brand Deals': {title: 'Project Name', value: 'project_name'}});
    // const [callingTunesDonutChartDimensions, setCallingTunesDonutChartDimensions] = useState<
    //     Record<string, DimensionType>
    // >({CallingTunes: {title: 'Artist', value: 'artist'}});

    //
    // Filters
    //
    const [filters, setFilters] = useAtom(filtersAtom);
    const [submittedFilters, setSubmittedFilters] = useAtom(submittedFiltersAtom);
    const [defaultFilters, setDefaultFilters] = useAtom(defaultFiltersAtom);

    const [invoicesDataSource, setInvoicesDataSource] = useState<RevenuesByOwnerType>({
        currency: 43,
        data: [],
        total: 0
    });
    // Todo maybe cancelToken is not necessary
    const [cancelToken, setCancelToken] = useState<CancelTokenSource | null>(null);
    const [annotations, setAnnotations] = useState<any>();

    //
    // Donut Props
    //
    const [donutProps, setDonutProps] = useAtom(donutPropsAtom);
    const [invoicesDonutProps, setInvoicesDonutProps] = useAtom(invoicesDonutPropsAtom);
    const [callingTunesDonutProps, setCallingTunesDonutProps] = useAtom(callingTunesDonutPropsAtom);

    // const [customers, setCustomersAtom] = useAtom(customersAtom);
    const [, setDateRange] = useAtom(dateRangeAtom);
    const [selectRef, setSelectRef] = useAtom(formRefAtom);
    const [, setInvoiceTableEnabled] = useAtom(isInvoiceTableEnabledAtom);
    setSelectRef(useRef<any>(null));
    const ref = useRef<ProFormInstance<any>>(null);

    const [selectedColumnTab, setSelectedColumnTab] = useState<ColumnTabsType>('totalRevenuesByOwner');
    const [selectedPieTab, setSelectedPieTab] = useState<PieTabsType>('YouTube (Labels)');
    const [selectedTagCategoryValues, setSelectedTagCategoryValues] = useState<string>("");

    //
    // Modal
    //
    const {isExtensiveModalVisible, setIsExtensiveModalVisible} = useContext(GlobalStateContext);

    const showModal = () => {
        setIsExtensiveModalVisible!(true);
    };

    // useEffect(() => {
    //     console.log('filters atom: ', filters);
    //     console.log('submitted filters atom: ', submittedFilters);
    // }, [filters, submittedFilters]);

    // useEffect(() => {
    //     console.log('donutProps: ', donutProps);
    // }, [donutProps]);

    const getManagedSelectedCustomers = async (internalSuppliers: number[], externalSuppliers: number[], internalCustomers: number[], externalCustomers: number[], selectedTagCategoryValues: string) => {
        try {
            setFetchingCustomers(true)

            // If there are any tags selected
            // Fetch all internal supplers, external suppliers, internal customers and external customers that are associated with those tags
            // Store them to filters
            // console.log(selectedTagCategoryValues)
            // console.log("Internal Suppliers right before the call: ", internalSuppliers)
            // console.log("External Suppliers right before the call: ", externalSuppliers)
            // console.log("Internal Customers right before the call: ", internalCustomers)
            // console.log("External Customers right before the call: ", externalCustomers)

            const res = await axiosClient.portal.post('/api/filters/managed_customers', {
                internalSuppliers: internalSuppliers,
                externalSuppliers: externalSuppliers,
                internalCustomers: internalCustomers,
                externalCustomers: externalCustomers,
                selectedTagCategoryValues: selectedTagCategoryValues,
            });

            setFetchingCustomers(false)

            return {
                internalSuppliers: res.data.internalSuppliers,
                externalSuppliers: res.data.externalSuppliers,
                internalCustomers: res.data.internalCustomers,
                externalCustomers: res.data.externalCustomers,
            }

        } catch (e: any) {
            if (axios.isCancel(e)) {
                // Cancel the previous request if it's still running
                // This message is shown when filters change quicker than backend has sent a response
                console.log('Request canceled:', e.message);
            } else {
                console.log(e);
                notification.error({message: e.response.data.message});
            }
        }
    }

    const getDataV2 = async (internalSuppliers: number[], externalSuppliers: number[], internalCustomers: number[], externalCustomers: number[]) => {
        try {
            setIsLoading(true);
            setHasInvoices(false);

            // GetData is called onMount (when the page loads for the first time) and whenever the user clicks submit
            // Whenever GetData is called, return the selected Column Graph tab to its initial state (the "Net Revenue" tab)
            setSelectedColumnTab("totalRevenuesByOwner")
            // setSelectedPieTab("YouTube (Labels)")

            // Cancel the previous request if it's still running
            if (cancelToken) {
                cancelToken.cancel('Request canceled');
            }

            const newCancelToken = axios.CancelToken.source();
            setCancelToken(newCancelToken);

            //
            // Fetch Column Graphs data and the Top 5 Platforms
            //
            const res = await axiosClient.portal.post('/api/employees/getchartdata',
                {
                    startDate: filters.startDate,
                    endDate: filters.endDate,
                    // customers: filters.customers,
                    internalSuppliers: internalSuppliers,
                    externalSuppliers: externalSuppliers,
                    internalCustomers: internalCustomers,
                    externalCustomers: externalCustomers,
                    metricType: filters.metricType
                }, {
                    cancelToken: newCancelToken.token
                });

            //
            // Invoices by month (Net Revenue)
            //
            let invoices = [];
            // Fetch outgoing invoices (Invoices' issue dates must be between given start and end dates)
            if (filters.metricType === 'Dm') {

                if (internalCustomers.length > 0 || externalCustomers.length > 0) {
                    invoices = await axiosClient.brands
                        .get(`/api/invoices/outgoing/monthly`, {
                            params: {
                                since: filters.startDate,
                                until: filters.endDate,
                                // id: filters.internalCustomers
                                accounting_customer_id: internalCustomers,
                                external_buyer_id: externalCustomers
                            },
                            paramsSerializer: (params) => {
                                return qs.stringify(params, {arrayFormat: 'brackets'});
                            }
                        })
                        .then(({data}) => data)
                        .catch((e) => {
                            console.error(e);
                            return [];
                        });
                }
            }
            // Fetch incoming invoices (Invoices' issue dates must be between given start and end dates)
            if (filters.metricType === 'Customer') {

                if (internalSuppliers.length > 0 || externalSuppliers.length > 0) {
                    invoices = await axiosClient.brands
                        .get('/api/invoices/incoming/monthly', {
                            params: {
                                since: filters.startDate,
                                until: filters.endDate,
                                // id: filters.internalSuppliers
                                supplier_customer_id: internalSuppliers,
                                supplier_external_id: externalSuppliers
                            },
                            paramsSerializer: (params) => {
                                return qs.stringify(params, {arrayFormat: 'brackets'});
                            }
                        })
                        .then(({data}) => data)
                        .catch((e) => {
                            console.error(e);
                            return [];
                        });
                }
            }


            let tempDataSource = JSON.parse(JSON.stringify(res.data));

            if (internalSuppliers.length === 0 && internalCustomers.length > 0) {
                tempDataSource.totalRevenuesByOwner.total = 0;
                tempDataSource.totalRevenuesByOwner.data = [];
            }

            // If there are any invoices (according to the given filters)
            if (invoices.length > 0) {
                setHasInvoices(true);
                const invoice_total: number = invoices.reduce(
                    (prev: number, curr: any) => prev + curr.revenue,
                    0
                );
                // Add invoices total revenues to "Total Revenue" (the already existing total revenue from all digital platforms)
                tempDataSource.totalRevenuesByOwner.total += invoice_total;
                const invoiceRevenuesObject: RevenuesByOwnerType = {
                    currency: dataSource ? dataSource.totalRevenuesByOwner.currency : 43,
                    data: invoices,
                    total: invoices.reduce(
                        (total: number, curr: RevenuesByOwnerDataType) => curr.revenue + total,
                        0
                    )
                };
                setInvoicesDataSource(invoiceRevenuesObject);
            }

            tempDataSource.totalRevenuesByOwner.data.push(...invoices);


            // Update dataSource, adding the invoices revenues that you just fetched
            setDataSource(tempDataSource);

            //
            // Initialize donut metrics for top 5 digital platforms.
            // Each platform is a different tab, containing a pie graph.
            // So everytime there will be 5 tabs, one for each top platform.
            // Pie graphs' tabs can include the following categories:
            // 1. YouTube (Channels)
            // 2. YouTube (Labels)
            // 3. Spotify
            // 4. Apple Music
            // 5. iTunes
            // 6. Deezer
            //
            let initialDonutMetrics: any = {};
            res.data.tabs.map((t: any) => {
                initialDonutMetrics[t.name] = {};
                initialDonutMetrics[t.name]['metric'] = t.metrics[0];
                initialDonutMetrics[t.name]['dimension'] = t.dimensions[0];
            });
            initialDonutMetrics['Calling Tunes'] = {metric: 'Revenue', dimension: 'Artist'};
            initialDonutMetrics['Music Publishing'] = {metric: 'Revenue', dimension: 'Label'}; // Possible dimensions: Label, Song, Territory

            // Set donut chart values
            setDonutChartMetrics(initialDonutMetrics);

            //
            // SET ALL DONUT PROPS
            // Including digital platforms, invoices and callingTunes donut props
            //
            // !! REFACTOR -- Διαχωρισμος της συλλογής data σε διαφορετικές συναρτήσεις. το Donut props να μην το θετει η παρουσα συνάρτηση
            // if res tabs is at least len 1 then set donut props
            if (res.data.tabs.length <= 0) return;
            // Digital Platforms
            setDonutProps({
                metric_type: filters.metricType,
                managed_customers: internalSuppliers,
                startDate: filters.startDate,
                endDate: filters.endDate,
                platform: res.data.tabs[0].name,
                metric: initialDonutMetrics[res.data.tabs[0].name]['metric'],
                dimension: initialDonutMetrics[res.data.tabs[0].name]['dimension']
            });

            // Invoices

            // Old (fixed) Method
            // setInvoicesDonutProps({
            //     metric_type: filters.metricType,
            //     dimension: 'project_name',
            //     internalSuppliers: filters.internalSuppliers,
            //     externalSuppliers: filters.externalSuppliers,
            //     internalCustomers: filters.internalCustomers,
            //     externalCustomers: filters.externalCustomers,
            //     startDate: filters.startDate,
            //     endDate: filters.endDate
            // });

            // New Method
            // Change all fields affected by the general filters (on top of Internal Dashboard's page)
            // And leave the other fields (dimension) unchanged. These fields will change when the donut props' radio buttons change.
            setInvoicesDonutProps((prevInvoicesDonutProps) => ({
                ...prevInvoicesDonutProps,
                metric_type: filters.metricType,
                internalSuppliers: internalSuppliers,
                externalSuppliers: externalSuppliers,
                internalCustomers: internalCustomers,
                externalCustomers: externalCustomers,
                startDate: filters.startDate,
                endDate: filters.endDate
            }));

            // CallingTunes
            // Change all fields affected by the general filters (on top of Internal Dashboard's page)
            // And leave the other fields (dimension) unchanged. These fields will change when the donut props' radio buttons change.
            setCallingTunesDonutProps((prevCallingTunesDonutProps) => ({
                ...prevCallingTunesDonutProps,
                metric_type: filters.metricType,
                managed_customers: internalSuppliers,
                startDate: filters.startDate,
                endDate: filters.endDate
            }));
        } catch (e: any) {
            if (axios.isCancel(e)) {
                // Cancel the previous request if it's still running
                // This message is shown when filters change quicker than backend has sent a response
                console.log('Request canceled:', e.message);
            } else {
                console.log(e);
                notification.error({message: e.response.data.message});
            }
        }
    };

    const formAnnotations = useCallback(() => {
        //
        // Annotations
        //
        const annotations: any = {};

        if (!currencyMap) return;

        if (dataSource) {
            let annotationsArr: any = [];

            // totalOwnedRevenuesByLabel
            if (dataSource.totalOwnedRevenuesByLabel) {
                each(groupBy(dataSource.totalOwnedRevenuesByLabel.data, 'month'), (values, k) => {
                    let value = values.reduce((a: any, b: { revenue: any }) => a + b.revenue, 0);

                    let finalValue = Math.round((value as number) * 100) / 100;
                    annotationsArr.push({
                        type: 'text',
                        position: [k, value],
                        content: `${
                            (finalValue.toLocaleString('el-GR') || 0) +
                            ' ' +
                            currencyMap[dataSource.totalOwnedRevenuesByLabel.currency]
                        }`,
                        style: {
                            textAlign: 'center',
                            fontSize: 14,
                            fill: 'rgba(0,0,0,0.85)'
                        },
                        offsetY: -15
                    });
                });
                annotations['totalOwnedRevenuesByLabel'] = annotationsArr;
            }

            // totalOwnedRevenuesByChannel
            if (dataSource.totalOwnedRevenuesByChannel) {
                annotationsArr = [];
                each(groupBy(dataSource.totalOwnedRevenuesByChannel.data, 'month'), (values, k) => {
                    let value = values.reduce((a: any, b: { revenue: any }) => a + b.revenue, 0);

                    let finalValue = Math.round((value as number) * 100) / 100;
                    annotationsArr.push({
                        type: 'text',
                        position: [k, value],
                        content: `${
                            (finalValue.toLocaleString('el-GR') || 0) +
                            ' ' +
                            currencyMap[dataSource.totalOwnedRevenuesByChannel.currency]
                        }`,
                        style: {
                            textAlign: 'center',
                            fontSize: 14,
                            fill: 'rgba(0,0,0,0.85)'
                        },
                        offsetY: -15
                    });
                });
                annotations['totalOwnedRevenuesByChannel'] = annotationsArr;
            }

            // totalOwnedRevenuesByCallingTune
            if (dataSource.totalCallingTunesByCustomer) {
                annotationsArr = [];
                each(groupBy(dataSource.totalCallingTunesByCustomer.data, 'month'), (values, k) => {
                    let value = values.reduce((a: any, b: { revenue: any }) => a + b.revenue, 0);

                    let finalValue = Math.round((value as number) * 100) / 100;
                    annotationsArr.push({
                        type: 'text',
                        position: [k, value],
                        content: `${
                            (finalValue.toLocaleString('el-GR') || 0) +
                            ' ' +
                            currencyMap[dataSource.totalCallingTunesByCustomer.currency]
                        }`,
                        style: {
                            textAlign: 'center',
                            fontSize: 14,
                            fill: 'rgba(0,0,0,0.85)'
                        },
                        offsetY: -15
                    });
                });
                annotations['totalCallingTunesByCustomer'] = annotationsArr;
            }

            // totalOwnedRevenuesByProduct
            if (dataSource.totalOwnedRevenuesByProduct) {
                annotationsArr = [];
                each(groupBy(dataSource.totalOwnedRevenuesByProduct.data, 'month'), (values, k) => {
                    let value = values.reduce((a: any, b: { revenue: any }) => a + b.revenue, 0);

                    let finalValue = Math.round((value as number) * 100) / 100;
                    annotationsArr.push({
                        type: 'text',
                        position: [k, value],
                        content: `${
                            (finalValue.toLocaleString('el-GR') || 0) +
                            ' ' +
                            currencyMap[dataSource.totalOwnedRevenuesByProduct.currency]
                        }`,
                        style: {
                            textAlign: 'center',
                            fontSize: 14,
                            fill: 'rgba(0,0,0,0.85)'
                        },
                        offsetY: -15
                    });
                });
                annotations['totalOwnedRevenuesByProduct'] = annotationsArr;
            }

            // totalRevenuesByOwner
            if (dataSource.totalRevenuesByOwner) {
                //totalRevenuesByMonth
                annotationsArr = [];
                each(groupBy(dataSource.totalRevenuesByOwner.data, 'month'), (values, k) => {
                    let value = values.reduce((a: any, b: { revenue: any }) => a + b.revenue, 0);

                    let finalValue = Math.round((value as number) * 100) / 100;
                    annotationsArr.push({
                        type: 'text',
                        position: [k, value],
                        content: `${
                            (finalValue.toLocaleString('el-GR') || 0) +
                            ' ' +
                            currencyMap[dataSource.totalRevenuesByOwner.currency]
                        }`,
                        style: {
                            textAlign: 'center',
                            fontSize: 14,
                            fill: 'rgba(0,0,0,0.85)'
                        },
                        offsetY: -15
                    });
                });

                //set state
                annotations['totalRevenuesByOwner'] = annotationsArr;
            }

            // totalInvoicesByProject
            if (dataSource.totalInvoicesByProject) {
                annotationsArr = [];
                each(groupBy(dataSource.totalInvoicesByProject.data, 'month'), (values, k) => {
                    let value = values.reduce((a: any, b: { revenue: any }) => a + b.revenue, 0);

                    let finalValue = Math.round((value as number) * 100) / 100;
                    annotationsArr.push({
                        type: 'text',
                        position: [k, value],
                        content: `${
                            (finalValue.toLocaleString('el-GR') || 0) +
                            ' ' +
                            currencyMap[
                                dataSource?.totalInvoicesByProject?.currency
                                    ? dataSource.totalInvoicesByProject.currency
                                    : 43
                                ]
                        }`,
                        style: {
                            textAlign: 'center',
                            fontSize: 14,
                            fill: 'rgba(0,0,0,0.85)'
                        },
                        offsetY: -15
                    });
                });

                //set state
                annotations['totalInvoicesByProject'] = annotationsArr;
            }

            // totalInvoicesByCustomer
            if (dataSource.totalInvoicesByCustomer) {
                annotationsArr = [];
                each(groupBy(dataSource.totalInvoicesByCustomer.data, 'month'), (values, k) => {
                    let value = values.reduce((a: any, b: { revenue: any }) => a + b.revenue, 0);

                    let finalValue = Math.round((value as number) * 100) / 100;
                    annotationsArr.push({
                        type: 'text',
                        position: [k, value],
                        content: `${
                            (finalValue.toLocaleString('el-GR') || 0) +
                            ' ' +
                            currencyMap[
                                dataSource?.totalInvoicesByCustomer?.currency
                                    ? dataSource.totalInvoicesByCustomer.currency
                                    : 43
                                ]
                        }`,
                        style: {
                            textAlign: 'center',
                            fontSize: 14,
                            fill: 'rgba(0,0,0,0.85)'
                        },
                        offsetY: -15
                    });
                });

                //set state
                annotations['totalInvoicesByCustomer'] = annotationsArr;
            }

            // totalCallingTunesByCustomer
            if (dataSource.totalCallingTunesByCustomer) {
                annotationsArr = [];
                each(groupBy(dataSource.totalCallingTunesByCustomer.data, 'month'), (values, k) => {
                    let value = values.reduce((a: any, b: { revenue: any }) => a + b.revenue, 0);

                    let finalValue = Math.round((value as number) * 100) / 100;
                    annotationsArr.push({
                        type: 'text',
                        position: [k, value],
                        content: `${
                            (finalValue.toLocaleString('el-GR') || 0) +
                            ' ' +
                            currencyMap[
                                dataSource?.totalCallingTunesByCustomer?.currency
                                    ? dataSource.totalCallingTunesByCustomer.currency
                                    : 43
                                ]
                        }`,
                        style: {
                            textAlign: 'center',
                            fontSize: 14,
                            fill: 'rgba(0,0,0,0.85)'
                        },
                        offsetY: -15
                    });
                });

                //set state
                annotations['totalCallingTunesByCustomer'] = annotationsArr;
            }

            // totalMusicPublishingByCustomer
            if (dataSource.totalMusicPublishingByCustomer) {
                annotationsArr = [];
                each(groupBy(dataSource.totalMusicPublishingByCustomer.data, 'month'), (values, k) => {
                    let value = values.reduce((a: any, b: { revenue: any }) => a + b.revenue, 0);

                    let finalValue = Math.round((value as number) * 100) / 100;
                    annotationsArr.push({
                        type: 'text',
                        position: [k, value],
                        content: `${
                            (finalValue.toLocaleString('el-GR') || 0) +
                            ' ' +
                            currencyMap[
                                dataSource?.totalMusicPublishingByCustomer?.currency
                                    ? dataSource.totalMusicPublishingByCustomer.currency
                                    : 43
                                ]
                        }`,
                        style: {
                            textAlign: 'center',
                            fontSize: 14,
                            fill: 'rgba(0,0,0,0.85)'
                        },
                        offsetY: -15
                    });
                });

                //set state
                annotations['totalMusicPublishingByCustomer'] = annotationsArr;
            }

            setAnnotations(annotations);
        }

        setIsLoading(false);
    }, [dataSource, currencyMap]);

    const onRadioButtonChange = (value: any, product: string, metricOrDimension: string) => {
        // console.log("value: ", value, "\n", "product: ", product, "\n", "metricOrDimension: ", metricOrDimension)
        setDonutChartMetrics((prevMetrics) => ({
            ...prevMetrics,
            [product]: {
                ...prevMetrics[product],
                [metricOrDimension]: value
            }
        }));

        setDonutProps((prevProps) => ({
            ...prevProps!,
            [metricOrDimension]: value
        }));
    };

    const onInvoicesRadioButtonChange = (title: string, value: string) => {
        setInvoicesDonutChartDimensions({
            'Brand Deals': {
                title: title,
                value: value
            }
        });

        setInvoicesDonutProps((prevProps) => ({
            ...prevProps!,
            dimension: value
        }));
    };

    // const onCallingTunesRadioButtonChange = (title: string, value: string) => {
    //     setCallingTunesDonutChartDimensions({
    //         CallingTunes: {
    //             title: title,
    //             value: value
    //         }
    //     });
    //
    //     setCallingTunesDonutProps((prevProps) => ({
    //         ...prevProps!,
    //         dimension: value
    //     }));
    // };

    const items: TabsProps['items'] = [
        {
            key: 'totalRevenuesByOwner',
            label: `Net Revenue`,
            disabled: dataSource?.totalRevenuesByOwner.data.length === 0,
            children: (
                <ColumnGraph
                    dataSet={dataSource?.totalRevenuesByOwner}
                    annotations={annotations ? annotations['totalRevenuesByOwner'] : []}
                    statisticTitle='Total Revenue'
                    seriesField='owner'
                />
            )
        },
        {
            key: 'totalOwnedRevenuesByLabel',
            label: `Music Distribution`,
            disabled:
                isLoading ||
                dataSource?.totalOwnedRevenuesByLabel.data.length === 0 ||
                (submittedFilters.internalSuppliers.length === 0 &&
                    submittedFilters.internalCustomers.length !== 0),
            children: (
                <ColumnGraph
                    dataSet={dataSource?.totalOwnedRevenuesByLabel}
                    annotations={annotations ? annotations['totalOwnedRevenuesByLabel'] : []}
                    seriesField='asset'
                />
            )
        },
        {
            key: 'totalOwnedRevenuesByChannel',
            label: `YouTube`,
            // style: dataSource?.totalOwnedRevenuesByChannel.data.length == 0 ? {display: "none"} : {},
            disabled:
                isLoading ||
                dataSource?.totalOwnedRevenuesByChannel.data.length === 0 ||
                (submittedFilters.internalSuppliers.length === 0 &&
                    submittedFilters.internalCustomers.length !== 0),
            children: (
                <ColumnGraph
                    dataSet={dataSource?.totalOwnedRevenuesByChannel}
                    annotations={annotations ? annotations['totalOwnedRevenuesByChannel'] : []}
                    seriesField={'asset'}
                />
            )
        },
        {
            key: 'totalCallingTunesByCustomer',
            label: `Calling Tunes`,
            // style: dataSource?.totalOwnedRevenuesByChannel.data.length == 0 ? {display: "none"} : {},
            disabled:
                isLoading ||
                dataSource?.totalCallingTunesByCustomer.data.length === 0 ||
                (submittedFilters.internalSuppliers.length === 0 &&
                    submittedFilters.internalCustomers.length !== 0),
            children: (
                <ColumnGraph
                    dataSet={dataSource?.totalCallingTunesByCustomer}
                    annotations={annotations ? annotations['totalCallingTunesByCustomer'] : []}
                    seriesField={'asset'}
                />
            )
        },
        {
            key: 'totalMusicPublishingByCustomer',
            label: `Music Publishing`,
            // style: dataSource?.totalOwnedRevenuesByChannel.data.length == 0 ? {display: "none"} : {},
            disabled:
                isLoading ||
                dataSource?.totalMusicPublishingByCustomer.data.length === 0 ||
                (submittedFilters.internalSuppliers.length === 0 &&
                    submittedFilters.internalCustomers.length !== 0),
            children: (
                <ColumnGraph
                    dataSet={dataSource?.totalMusicPublishingByCustomer}
                    annotations={annotations ? annotations['totalMusicPublishingByCustomer'] : []}
                    seriesField={'asset'}
                />
            )
        },
        {
            key: 'totalOwnedRevenuesByProduct',
            label: `Platforms`,
            disabled:
                isLoading ||
                dataSource?.totalOwnedRevenuesByProduct.data.length === 0 ||
                (submittedFilters.internalSuppliers.length === 0 &&
                    submittedFilters.internalCustomers.length !== 0),
            children: (
                <ColumnGraph
                    dataSet={{
                        currency: dataSource?.totalOwnedRevenuesByProduct.currency,
                        data: dataSource?.totalOwnedRevenuesByProduct?.data.filter(i => i.product !== ''),
                        total: dataSource?.totalOwnedRevenuesByProduct.total
                    } as RevenuesByProductType}
                    annotations={annotations ? annotations['totalOwnedRevenuesByProduct'] : []}
                    seriesField={'product'}
                />
            )
        },
        // {
        //     key: 'totalInvoicesByProject',
        //     label: `Brand Deals`,
        //     disabled:
        //         dataSource?.totalInvoicesByProject?.data.length === 0 ||
        //         submittedFilters.metricType === 'Customer',
        //     children: (
        //         <ColumnGraph
        //             dataSet={dataSource?.totalInvoicesByProject}
        //             annotations={annotations ? annotations['totalInvoicesByProject'] : []}
        //             seriesField={'project_name'}
        //         />
        //     )
        // },
        // {
        //     key: 'totalInvoicesByCustomer',
        //     label: `Brand Deals`,
        //     disabled:
        //         dataSource?.totalInvoicesByCustomer?.data.length === 0 ||
        //         submittedFilters.metricType === 'Dm',
        //     children: (
        //         <ColumnGraph
        //             dataSet={dataSource?.totalInvoicesByCustomer}
        //             annotations={annotations ? annotations['totalInvoicesByCustomer'] : []}
        //             seriesField={'customer_name'}
        //         />
        //     )
        // }
        {
            key: 'totalInvoicesByProject',
            label: `Brand Deals`,
            disabled: isLoading || dataSource?.totalInvoicesByProject?.data.length === 0,
            children: (
                <ColumnGraph
                    dataSet={dataSource?.totalInvoicesByProject}
                    annotations={annotations ? annotations['totalInvoicesByProject'] : []}
                    seriesField={'project_name'}
                />
            )
        },
    ].filter((item: any) => !item.disabled); // Filters disabled tabs
    // If disabled fixed to false for a tab, then this tab will always be visible
    // Remove filter to keep only the "disabled" logic for tabs with no data

    // It's called everytime the values inside the array change (the array at the end, after the function)
    // Now that the array is empty, useEffect is called only once, at the start
    useEffect(() => {
        // on mount
        getManagedSelectedCustomers(
            defaultFilters.internalSuppliers,
            defaultFilters.externalSuppliers,
            defaultFilters.internalCustomers,
            defaultFilters.externalCustomers,
            selectedTagCategoryValues).then((result) => {
            if (result) {
                getDataV2(
                    result.internalSuppliers,
                    result.externalSuppliers,
                    result.internalCustomers,
                    result.externalCustomers);
                setSubmittedFilters(filters);
            }
        });

        // on unmount
        return () => {
            setFilters(defaultFilters);
            setSubmittedFilters(defaultFilters);
        };
    }, []);

    useEffect(() => {
        // Set Annotations
        formAnnotations();
    }, [dataSource]);

    // useEffect(() => {
    //     console.log(selectedColumnTab)
    // }, [selectedColumnTab]);

    // useEffect(() => {
    //     console.log(selectedPieTab)
    // }, [selectedPieTab]);

    // useEffect(() => {
    //     console.log({donutChartMetrics});
    // }, [donutChartMetrics]);
    // useEffect(() => {
    //     console.log({donutProps});
    // }, [donutProps]);

    useEffect(() => {
        // todo change to NEW METHOD (as you did in GetData)
        // todo Change all fields affected by the general filters (on top of Internal Dashboard's page)
        // todo And leave the other fields (dimension) unchanged. These fields will change when the donut props' radio buttons change.
        // console.log({submittedFilters})
        // todo OR Keep it that way here. Think about it.
        // Old Method
        // setInvoicesDonutProps({
        //     internalSuppliers: submittedFilters.internalSuppliers,
        //     externalSuppliers: submittedFilters.externalSuppliers,
        //     internalCustomers: submittedFilters.internalCustomers,
        //     externalCustomers: submittedFilters.externalCustomers,
        //     metric_type: submittedFilters.metricType,
        //     dimension: 'project_name',
        //     startDate: submittedFilters.startDate,
        //     endDate: submittedFilters.endDate
        // });

        // New Method
        // Change all fields affected by the general filters (on top of Internal Dashboard's page)
        // And leave the other fields (dimension) unchanged. These fields will change when the donut props' radio buttons change.
        setInvoicesDonutProps((prevInvoicesDonutProps) => ({
            ...prevInvoicesDonutProps,
            metric_type: filters.metricType,
            internalSuppliers: filters.internalSuppliers,
            externalSuppliers: filters.externalSuppliers,
            internalCustomers: filters.internalCustomers,
            externalCustomers: filters.externalCustomers,
            startDate: filters.startDate,
            endDate: filters.endDate
        }));

        // todo Whatever method you choose for invoices, DO THE SAME FOR REALTONES!!!
        // CallingTunes
        // Change all fields affected by the general filters (on top of Internal Dashboard's page)
        // And leave the other fields (dimension) unchanged. These fields will change when the donut props' radio buttons change.
        setCallingTunesDonutProps((prevCallingTunesDonutProps) => ({
            ...prevCallingTunesDonutProps,
            metric_type: filters.metricType,
            managed_customers: filters.internalSuppliers,
            startDate: filters.startDate,
            endDate: filters.endDate
        }));
    }, [submittedFilters]);

    const handleValuesChange = (changedValues: any, values: any) => {

        let selectedTags: string = ""
        if (values.tags != undefined) {
            selectedTags = values.tags.join(",")
        }
        setSelectedTagCategoryValues(selectedTags);
        // console.log(selectedTags);

        const is_group = getCustomerArrayByGroup(values.customers ? values.customers : [], 'IS');
        const es_group = getCustomerArrayByGroup(values.customers ? values.customers : [], 'ES');
        const ic_group = getCustomerArrayByGroup(values.customers ? values.customers : [], 'IC');
        const ec_group = getCustomerArrayByGroup(values.customers ? values.customers : [], 'EC');

        // console.log("Tags - On Change Values: ", selectedTags)
        // console.log("Internal Suppliers On Change Values: ", is_group)
        // console.log("External Suppliers On Change Values: ", es_group)
        // console.log("Internal Customers On Change Values: ", ic_group)
        // console.log("External Customers On Change Values: ", ec_group)

        getManagedSelectedCustomers(
            is_group,
            es_group,
            ic_group,
            ec_group,
            selectedTags
        ).then((result) => {
            if (result) {
                setFilters((prevFilters) => ({
                    ...prevFilters,
                    ...changedValues,
                    internalSuppliers: result.internalSuppliers,
                    externalSuppliers: result.externalSuppliers,
                    internalCustomers: result.internalCustomers,
                    externalCustomers: result.externalCustomers,
                }));
            } else {
                console.error('Failed to fetch managed selected customers.');
            }
        }).catch((error) => {
            console.error('Error fetching managed selected customers:', error);
        });
    };

    if (isLoading) {
        return <DashboardLoading filters={filters} type='INTERNAL'/>;
    }

    return (
        <PageContainer>
            <>
                {/*<Row>*/}
                <ConfigProvider locale={enUSIntl}>
                    <ProForm<FiltersType>
                        layout={'inline'}
                        onValuesChange={handleValuesChange}
                        initialValues={filters}
                        submitter={{
                            resetButtonProps: false,
                            submitButtonProps: {style: {marginLeft: 8}, loading: isLoading, disabled: fetchingCustomers}
                        }}
                        formRef={ref}
                        // onFinish={async ({customers}) => {
                        onFinish={async () => {
                            setDateRange({
                                start_date: filters.startDate,
                                end_date: filters.endDate
                            });

                            // console.log("Tags - On Finish: ", selectedTagCategoryValues)
                            // console.log("Internal Suppliers On Finish: ", filters.internalSuppliers)
                            // console.log("External Suppliers On Finish: ", filters.externalSuppliers)
                            // console.log("Internal Customers On Finish: ", filters.internalCustomers)
                            // console.log("External Customers On Finish: ", filters.externalCustomers)

                            getManagedSelectedCustomers(
                                filters.internalSuppliers,
                                filters.externalSuppliers,
                                filters.internalCustomers,
                                filters.externalCustomers,
                                selectedTagCategoryValues).then((result) => {
                                if (result) {
                                    getDataV2(
                                        result.internalSuppliers,
                                        result.externalSuppliers,
                                        result.internalCustomers,
                                        result.externalCustomers);
                                    setSubmittedFilters(filters);
                                }
                            });
                        }}
                    >
                        <ProFormRadio.Group
                            name='metricType'
                            label='Revenue'
                            radioType={'button'}
                            initialValue={'Dm'}
                            options={[
                                {
                                    label: 'DM',
                                    value: 'Dm'
                                },
                                {
                                    label: 'Customers',
                                    value: 'Customer'
                                }
                            ]}
                        />

                        <ProFormSelect
                            name='customers'
                            label='Select Customer'
                            placeholder="Please select a customer"
                            style={{width: '440px'}}
                            request={async () =>
                                await Promise.all([
                                    getInternalSuppliersPromise(),
                                    getExternalSuppliersPromise(),
                                    getInternalCustomersPromise(),
                                    getExternalCustomersPromise()
                                ]).catch((e) => {
                                    console.error(e);
                                    return [];
                                })
                            }
                            fieldProps={{
                                mode: 'multiple',
                                autoClearSearchValue: false,
                                maxTagCount: 'responsive',
                                // onKeyDown: (e) => {
                                //     if (e.code === 'Backspace') {
                                //         // console.log('backspace pressed');
                                //         // console.log(ref.current?.getFieldInstance("customers"))
                                //     }
                                // }
                            }}
                        />

                        <ProFormSelect
                            name='tags'
                            label='Select by Tag'
                            placeholder="Please select a tag"
                            style={{width: '400px'}}
                            request={async () =>
                                await Promise.all([
                                    getCountryTagsForInternalDashboard(),
                                    getPrimaryAccountManagerTagsForInternalDashboard(),
                                    getTierTagsForInternalDashboard()
                                ]).catch((e) => {
                                    console.error(e);
                                    return [];
                                })
                            }
                            fieldProps={{
                                mode: 'multiple',
                                autoClearSearchValue: false,
                                maxTagCount: 'responsive'
                            }}
                        />

                        <ProFormDateRangePicker
                            width='md'
                            name='monthRange'
                            initialValue={[
                                currDate.getFullYear() -
                                1 +
                                '-' +
                                (currDate.getMonth() + 1 < 10 ? '0' : '') +
                                (currDate.getMonth() + 1) +
                                '-01',
                                currDate.getFullYear() +
                                '-' +
                                (currDate.getMonth() + 1 < 10 ? '0' : '') +
                                (currDate.getMonth() + 1) +
                                '-01'
                            ]}
                            fieldProps={{
                                format: 'YYYY-MM',
                                picker: 'month',
                                placeholder: ['Start month', 'End month'],
                                value: [
                                    dayjs(filters.startDate, 'YYYY-MM'),
                                    dayjs(filters.endDate, 'YYYY-MM')
                                ]
                            }}
                            transform={(values: string[]) => {
                                return {
                                    startDate: values[0].substring(0, 7) + '-01' || undefined,
                                    endDate: values[1].substring(0, 7) + '-01' || undefined
                                };
                            }}
                            style={{flex: 1}}
                        />
                        {/*</Space>*/}
                    </ProForm>
                </ConfigProvider>
                {/*</Row>*/}

                <br/>

                {/*//*/}
                {/*// If it stopped loading (backend has returned data) and dataSource has zero data, then notify client that his financial data are not found*/}
                {/*//*/}
                {!isLoading &&
                dataSource?.totalRevenuesByOwner.data.length === 0 &&
                dataSource?.totalOwnedRevenuesByLabel.data.length === 0 &&
                dataSource?.totalOwnedRevenuesByChannel.data.length === 0 &&
                dataSource?.totalOwnedRevenuesByProduct.data.length === 0 &&
                dataSource?.totalInvoicesByProject.data.length === 0 &&
                dataSource?.totalInvoicesByCustomer.data.length === 0 &&
                dataSource?.totalCallingTunesByCustomer.data.length === 0 &&
                dataSource?.totalMusicPublishingByCustomer.data.length === 0 ? (
                    <>
                        <br/>
                        <br/>
                        <Empty/>
                    </>
                ) : (
                    <>
                        <Tabs
                            onChange={(key) => {
                                //
                                // When Column Graph (uper) tabs change!
                                //
                                setSelectedColumnTab(key as ColumnTabsType);

                                setInvoiceTableEnabled(
                                    key === 'totalRevenuesByOwner' ||
                                    key === 'totalInvoicesByProject' ||
                                    key === 'totalInvoicesByCustomer'
                                );

                                let donutTabKey = "YouTube (Labels)"
                                if (key === "totalOwnedRevenuesByChannel") {
                                    donutTabKey = "YouTube (Channels)"
                                } else if (key === "totalOwnedRevenuesByLabel") {
                                    for (let donutChartMetricsKey in donutChartMetrics) {
                                        if (donutChartMetricsKey != "YouTube (Channels)") {
                                            donutTabKey = donutChartMetricsKey
                                            break
                                        }
                                    }
                                } else if (key === "totalOwnedRevenuesByProduct") {
                                    for (let donutChartMetricsKey in donutChartMetrics) {
                                        donutTabKey = donutChartMetricsKey
                                        break
                                    }
                                } else if (key === "totalCallingTunesByCustomer") {
                                    donutTabKey = "Calling Tunes"
                                } else if (key === "totalMusicPublishingByCustomer") {
                                    donutTabKey = "Music Publishing"
                                }

                                setSelectedPieTab(donutTabKey as PieTabsType)

                                setDonutProps({
                                    ...donutProps!,
                                    platform: donutTabKey,
                                    metric: donutChartMetrics[donutTabKey]['metric'],
                                    dimension: donutChartMetrics[donutTabKey]['dimension']
                                });
                            }}
                            activeKey={selectedColumnTab}
                            defaultActiveKey='totalRevenuesByOwner'
                            items={items}
                        />

                        <br/>
                        <br/>

                        {selectedColumnTab === 'totalRevenuesByOwner' ? (
                            <TotalRevenuesPieMemo revenues={dataSource?.totalRevenuesByOwner}/>
                        ) : selectedColumnTab === 'totalInvoicesByProject' ||
                        selectedColumnTab === 'totalInvoicesByCustomer' ? (
                            <Tabs>
                                <TabPane tab={'Brand Deals'} key={'Brand Deals'}>
                                    <Row justify={'center'}>
                                        {invoicesDonutProps != null &&
                                            invoicesDonutProps.metric_type != null &&
                                            invoicesDonutProps.dimension != null && (
                                                <Suspense fallback={<Spin/>}>
                                                    <InvoiceDonutChart
                                                        {...invoicesDonutProps}
                                                        key={JSON.stringify(invoicesDonutProps)}
                                                    />
                                                </Suspense>
                                            )}
                                    </Row>
                                </TabPane>
                            </Tabs>
                        ) : selectedColumnTab === 'totalCallingTunesByCustomer' ? (
                            <Tabs>
                                <TabPane tab={'Calling Tunes'} key={'Calling Tunes'}>
                                    <Row justify={'space-between'}>
                                        {/*Metrics*/}
                                        <Radio.Group
                                            // value={'Revenue'}
                                            value={donutChartMetrics['Calling Tunes']?.metric}
                                            buttonStyle='solid'
                                            onChange={(e) =>
                                                onRadioButtonChange(
                                                    e.target.value,
                                                    'Calling Tunes',
                                                    'metric'
                                                )
                                            }
                                        >
                                            <Radio.Button value={'Revenue'} key={'Revenue'} defaultChecked>
                                                Revenue
                                            </Radio.Button>

                                            <Radio.Button value={'Times used'} key={'Times used'}>
                                                Times used
                                            </Radio.Button>

                                        </Radio.Group>

                                        {/*Dimensions*/}
                                        <Radio.Group
                                            // value={'Artist'}
                                            value={donutChartMetrics['Calling Tunes']?.dimension}
                                            buttonStyle='solid'
                                            onChange={(e) =>
                                                onRadioButtonChange(
                                                    e.target.value,
                                                    'Calling Tunes',
                                                    'dimension'
                                                )
                                            }
                                        >
                                            <Radio.Button value={'Artist'} key={'Artist'} defaultChecked>
                                                Artist
                                            </Radio.Button>
                                            <Radio.Button value={'Track'} key={'Track'}>
                                                Track
                                            </Radio.Button>

                                        </Radio.Group>
                                    </Row>

                                    <br/>

                                    <Row justify={'start'}>
                                        {donutProps != null &&
                                            donutProps.platform != null &&
                                            donutProps.metric != null &&
                                            donutProps.dimension != null && (
                                                <>
                                                    <ExtensiveDonutData
                                                        platform={donutProps.platform}
                                                        metric={donutProps.metric}
                                                        dimension={donutProps.dimension}
                                                        startDate={donutProps.startDate}
                                                        endDate={donutProps.endDate}
                                                        managed_customers={
                                                            donutProps.managed_customers
                                                        }
                                                        metric_type={donutProps.metric_type}
                                                        visible={isExtensiveModalVisible}
                                                        key={JSON.stringify(donutProps)}
                                                    />

                                                    <Alert
                                                        style={{textAlign: 'start'}}
                                                        message={
                                                            <>
                                                                <Typography.Text type={'secondary'}>
                                                                    {'For detailed view click '}
                                                                    <Typography.Link
                                                                        disabled={isLoading}
                                                                        onClick={() => {
                                                                            showModal();
                                                                        }}
                                                                    >
                                                                        here
                                                                    </Typography.Link>
                                                                    .
                                                                </Typography.Text>
                                                            </>
                                                        }
                                                        type={'info'}
                                                        showIcon={true}
                                                    />
                                                </>
                                            )}
                                    </Row>

                                    <Row justify={'center'}>
                                        {/*{callingTunesDonutProps != null &&*/}
                                        {/*    callingTunesDonutProps.metric_type != null &&*/}
                                        {/*    callingTunesDonutProps.dimension != null && (*/}
                                        {/*        <Suspense fallback={<Spin/>}>*/}
                                        {/*            <CallingTunesDonutChart*/}
                                        {/*                {...callingTunesDonutProps}*/}
                                        {/*                key={JSON.stringify(callingTunesDonutProps)}*/}
                                        {/*            />*/}
                                        {/*        </Suspense>*/}
                                        {/*    )}*/}
                                        {donutProps != null &&
                                            donutProps.platform != null &&
                                            donutProps.metric != null &&
                                            donutProps.dimension != null && (
                                                // <Suspense fallback={<Spin/>}>
                                                //     <CallingTunesDonutChart
                                                //         {...donutProps!}
                                                //         key={JSON.stringify(donutProps)}
                                                //     />
                                                // </Suspense>
                                                <InternalDonutTabContent
                                                    {...donutProps}
                                                    key={JSON.stringify(donutProps)}
                                                />
                                            )}
                                    </Row>
                                </TabPane>
                            </Tabs>
                        ) : selectedColumnTab === 'totalMusicPublishingByCustomer' ? (
                            <Tabs>
                                <TabPane tab={'Music Publishing'} key={'Music Publishing'}>
                                    <Row justify={'space-between'}>
                                        {/*Metrics*/}
                                        <Radio.Group
                                            // value={'Revenue'}
                                            value={donutChartMetrics['Music Publishing']?.metric}
                                            buttonStyle='solid'
                                            onChange={(e) =>
                                                onRadioButtonChange(
                                                    e.target.value,
                                                    'Music Publishing',
                                                    'metric'
                                                )
                                            }
                                        >
                                            <Radio.Button value={'Revenue'} key={'Revenue'} defaultChecked>
                                                Revenue
                                            </Radio.Button>

                                            <Radio.Button value={'Units'} key={'Units'}>
                                                Units
                                            </Radio.Button>

                                        </Radio.Group>

                                        {/*Dimensions*/}
                                        <Radio.Group
                                            // value={'Artist'}
                                            value={donutChartMetrics['Music Publishing']?.dimension}
                                            buttonStyle='solid'
                                            onChange={(e) =>
                                                onRadioButtonChange(
                                                    e.target.value,
                                                    'Music Publishing',
                                                    'dimension'
                                                )
                                            }
                                        >
                                            <Radio.Button value={'Label'} key={'Label'} defaultChecked>
                                                Label
                                            </Radio.Button>
                                            <Radio.Button value={'Song'} key={'Song'}>
                                                Song
                                            </Radio.Button>
                                            <Radio.Button value={'Country'} key={'Country'}>
                                                Country
                                            </Radio.Button>

                                        </Radio.Group>
                                    </Row>

                                    <br/>

                                    <Row justify={'start'}>
                                        {donutProps != null &&
                                            donutProps.platform != null &&
                                            donutProps.metric != null &&
                                            donutProps.dimension != null && (
                                                <>
                                                    <ExtensiveDonutData
                                                        platform={donutProps.platform}
                                                        metric={donutProps.metric}
                                                        dimension={donutProps.dimension}
                                                        startDate={donutProps.startDate}
                                                        endDate={donutProps.endDate}
                                                        managed_customers={
                                                            donutProps.managed_customers
                                                        }
                                                        metric_type={donutProps.metric_type}
                                                        visible={isExtensiveModalVisible}
                                                        key={JSON.stringify(donutProps)}
                                                    />

                                                    <Alert
                                                        style={{textAlign: 'start'}}
                                                        message={
                                                            <>
                                                                <Typography.Text type={'secondary'}>
                                                                    {'For detailed view click '}
                                                                    <Typography.Link
                                                                        disabled={isLoading}
                                                                        onClick={() => {
                                                                            showModal();
                                                                        }}
                                                                    >
                                                                        here
                                                                    </Typography.Link>
                                                                    .
                                                                </Typography.Text>
                                                            </>
                                                        }
                                                        type={'info'}
                                                        showIcon={true}
                                                    />
                                                </>
                                            )}
                                    </Row>

                                    <Row justify={'center'}>
                                        {donutProps != null &&
                                            donutProps.platform != null &&
                                            donutProps.metric != null &&
                                            donutProps.dimension != null && (
                                                <InternalDonutTabContent
                                                    {...donutProps}
                                                    key={JSON.stringify(donutProps)}
                                                />
                                            )}
                                    </Row>
                                </TabPane>
                            </Tabs>) : (
                            <Tabs
                                onChange={(key) => {
                                    //
                                    // When Pie Chart (lower) tabs change!
                                    //
                                    setSelectedPieTab(key as PieTabsType)

                                    setDonutProps({
                                        ...donutProps!,
                                        platform: key,
                                        metric: donutChartMetrics[key]['metric'],
                                        dimension: donutChartMetrics[key]['dimension']
                                    });
                                }}

                                activeKey={selectedPieTab}
                            >
                                {dataSource?.tabs
                                    .filter((tab) => {
                                        if (selectedColumnTab == 'totalOwnedRevenuesByLabel') {
                                            return (
                                                tab.name === 'YouTube (Labels)' ||
                                                tab.name === 'Spotify' ||
                                                tab.name === 'Apple Music' ||
                                                tab.name === 'iTunes' ||
                                                tab.name === 'Amazon Premium'
                                            );
                                        }

                                        if (selectedColumnTab == 'totalOwnedRevenuesByChannel') {
                                            return tab.name === 'YouTube (Channels)';
                                        }


                                        return true;
                                    })
                                    .map((tab: TabsType) => (
                                        <TabPane tab={tab.name} key={tab.name}>
                                            {/*<TabPane tab={donutProps.platform} key={tab.name}>*/}
                                            <Row justify={'space-between'}>
                                                {/*Metrics*/}
                                                <Radio.Group
                                                    value={donutChartMetrics[tab.name]?.metric}
                                                    buttonStyle='solid'
                                                    onChange={(e) =>
                                                        onRadioButtonChange(
                                                            e.target.value,
                                                            tab.name,
                                                            'metric'
                                                        )
                                                    }
                                                >
                                                    {tab.metrics.map((metric: any) => (
                                                        <Radio.Button value={metric} key={metric}>
                                                            {metric}
                                                        </Radio.Button>
                                                    ))}
                                                </Radio.Group>

                                                {/*Dimensions*/}
                                                <Radio.Group
                                                    value={donutChartMetrics[tab.name]?.dimension}
                                                    buttonStyle='solid'
                                                    onChange={(e) =>
                                                        onRadioButtonChange(
                                                            e.target.value,
                                                            tab.name,
                                                            'dimension'
                                                        )
                                                    }
                                                >
                                                    {tab.dimensions.map((dimension: any) => (
                                                        <Radio.Button value={dimension} key={dimension}>
                                                            {dimension}
                                                        </Radio.Button>
                                                    ))}
                                                </Radio.Group>
                                            </Row>

                                            <br/>

                                            <Row justify={'start'}>
                                                {donutProps != null &&
                                                    donutProps.platform != null &&
                                                    donutProps.metric != null &&
                                                    donutProps.dimension != null && (
                                                        <>
                                                            <ExtensiveDonutData
                                                                platform={donutProps.platform}
                                                                metric={donutProps.metric}
                                                                dimension={donutProps.dimension}
                                                                startDate={donutProps.startDate}
                                                                endDate={donutProps.endDate}
                                                                managed_customers={
                                                                    donutProps.managed_customers
                                                                }
                                                                metric_type={donutProps.metric_type}
                                                                visible={isExtensiveModalVisible}
                                                                key={JSON.stringify(donutProps)}
                                                            />

                                                            <Alert
                                                                style={{textAlign: 'start'}}
                                                                message={
                                                                    <>
                                                                        <Typography.Text type={'secondary'}>
                                                                            {'For detailed view click '}
                                                                            <Typography.Link
                                                                                disabled={isLoading}
                                                                                onClick={() => {
                                                                                    showModal();
                                                                                }}
                                                                            >
                                                                                here
                                                                            </Typography.Link>
                                                                            .
                                                                        </Typography.Text>
                                                                    </>
                                                                }
                                                                type={'info'}
                                                                showIcon={true}
                                                            />
                                                        </>
                                                    )}
                                            </Row>

                                            <Row justify={'center'}>
                                                {/*!!!!!!!!!! SOS TIP !!!!!!!!!!*/}
                                                {/*WHEN YOU RENDER A COMPONENT (CLASS) INSIDE .map() FUNCTION, ALWAYS USE "key" PROP!!!!!!!!!*/}
                                                {donutProps != null &&
                                                    donutProps.platform != null &&
                                                    donutProps.metric != null &&
                                                    donutProps.dimension != null && (
                                                        <InternalDonutTabContent
                                                            {...donutProps}
                                                            key={JSON.stringify(donutProps)}
                                                        />
                                                    )}
                                            </Row>
                                        </TabPane>
                                    ))}
                            </Tabs>
                        )}
                    </>
                )}
                <br/>
                {/*{hasInvoices && submittedFilters.customers.length !== 0 && (*/}
                {/*{hasInvoices && submittedFilters.customers.length !== 0 ? (*/}
                {hasInvoices && (submittedFilters.internalSuppliers.length > 0 || submittedFilters.externalSuppliers.length > 0 || submittedFilters.internalCustomers.length > 0 || submittedFilters.externalCustomers.length > 0) ? (
                    // {invoicesData != null && hasInvoices && (submittedFilters.internalSuppliers.length > 0 || submittedFilters.externalSuppliers.length > 0 || submittedFilters.internalCustomers.length > 0 || submittedFilters.externalCustomers.length > 0) ? (
                    <Suspense fallback={<Spin/>}>
                        <div
                            style={{
                                marginLeft: '1%',
                                marginRight: '1%',
                                marginTop: '2%',
                                marginBottom: '5%'
                            }}
                        >
                            {/*{console.log("BrandsInvoicesList filters: ", submittedFilters)}*/}
                            {/*<BrandsInvoicesList filters={filters}/>*/}
                            <BrandsInvoicesList/>
                        </div>
                    </Suspense>
                ) : <></>}
            </>
        </PageContainer>
    );
};

export default InternalDashboard;