import * as React from 'react';
import { useContext, useState } from 'react';
import { ConfigProvider, Modal, notification } from 'antd';
import { ProColumns } from '@ant-design/pro-components';
import enUSIntl from 'antd/es/locale/en_US';
import { ProTable } from '@ant-design/pro-table';
import axiosClient from '../api/axiosClient';
import { GlobalStateContext } from '../context/GlobalContext';
import axios, { CancelTokenSource } from 'axios';

const currencyMap: any = { 43: '€', 46: '£', 142: '$' };

export interface Props {
    platform?: string;
    metric?: string;
    dimension?: string;
    startDate: string;
    endDate: string;
    visible?: boolean;
}

type ExtensiveCustomerDonutParams = {
    metric: number;
    dimension: string;
};

// DataSource
export type DataSourceType = {
    data: ExtensiveCustomerDonutParams;
    total: number;
    currency: number;
};

const ExtensiveCustomerDonutData = (props: Props) => {
    const [search, setSearch] = useState<string>('');
    const [dataSource, setDataSource] = useState<DataSourceType>({
        data: { metric: 0, dimension: '' },
        total: 0.0,
        currency: 43
    });
    const { isExtensiveCustomerModalVisible, setIsExtensiveCustomerModalVisible } =
        useContext(GlobalStateContext);

    // Todo maybe cancelToken is not necessary
    const [cancelToken, setCancelToken] = useState<CancelTokenSource | null>(null);

    function handleOk() {
        setIsExtensiveCustomerModalVisible!(false);
    }

    function handleCancel() {
        setIsExtensiveCustomerModalVisible!(false);
    }

    const [pageSize, setPageSize] = useState<number>(6);

    const columns: ProColumns<ExtensiveCustomerDonutParams>[] = [
        { title: props.dimension, dataIndex: 'dimension', align: 'center', width: '66%' },
        {
            title: props.metric,
            dataIndex: 'metric',
            align: 'center',
            width: '33%',
            render: (dom, entity) => {
                return formatValue(entity.metric);
            }
        }
    ];

    function formatValue(value: number): string {
        let finalValue: number = Math.round(value * 100) / 100;
        let returnValue: string;

        if (props.metric == 'Revenue') {
            returnValue = (finalValue?.toLocaleString('el-GR') || 0) + ' ' + currencyMap[dataSource.currency];
        } else {
            returnValue = finalValue?.toLocaleString('el-GR') || '0';
        }

        return returnValue;
    }

    return (
        <Modal
            title='Detailed View'
            open={props.visible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={1100}
            bodyStyle={{ height: 470, overflowY: 'auto' }}
            closable={true}
            footer={null}
            mask={true}
        >
            <ConfigProvider locale={enUSIntl}>
                <ProTable<ExtensiveCustomerDonutParams>
                    headerTitle={'Total: ' + formatValue(dataSource!.total)}
                    params={{
                        keyword: search
                    }}
                    request={async (params, sort, filter) => {
                        try {
                            // Cancel the previous request if it's still running
                            if (cancelToken) {
                                cancelToken.cancel('Request canceled');
                            }

                            const newCancelToken = axios.CancelToken.source();
                            setCancelToken(newCancelToken);

                            const res = await axiosClient.portal.post(
                                '/api/analytics/getextensivedata',
                                {
                                    ...props,
                                    keyword: params.keyword
                                },
                                { cancelToken: newCancelToken.token }
                            );

                            let tempDataSource = JSON.parse(JSON.stringify(res.data));

                            setDataSource(tempDataSource);
                            // setLoading(false)

                            return { data: tempDataSource.data, success: true };
                        } catch (e: any) {
                            notification.error({ message: e.response.data.message });
                            // setLoading(false)
                            return { data: [], success: false };
                        }
                    }}
                    columns={columns}
                    rowKey='id'
                    // Pagination PageSize Fix (pagination fix, pagination bug fix)
                    pagination={{
                        pageSize,
                        hideOnSinglePage: false,
                        showQuickJumper: true,
                        pageSizeOptions: ['6', '10', '20', '50'], // Optional: Provide available page sizes
                        onShowSizeChange: (current, size) => setPageSize(size), // Update pageSize on change
                        showSizeChanger: true
                    }}
                    options={{
                        search: {
                            value: search,
                            onChange: (value) => {
                                setSearch(value.target.value);
                            },
                            allowClear: true,
                            style: { width: 300 }
                        }
                    }}
                    debounceTime={700}
                    search={false}
                    bordered
                />
            </ConfigProvider>
        </Modal>
    );
};

export default ExtensiveCustomerDonutData;
