import React, { useEffect, useState } from 'react';
import { notification, Spin } from 'antd';
import { Pie } from '@ant-design/plots';
import { DonutPropsType } from './Dashboard';
import axios, { CancelTokenSource } from 'axios';
import axiosClient from '../api/axiosClient';

const currencyMap: any = { 43: '€', 46: '£', 142: '$' };

const DonutTabContent = (props: DonutPropsType) => {
    const [dataSource, setDataSource] = useState<any>();
    const [filteredData, setFilteredData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    // Todo maybe cancelToken is not necessary
    const [cancelToken, setCancelToken] = useState<CancelTokenSource | null>(null);

    const getData = async () => {
        setLoading(true);
        try {
            // Cancel the previous request if it's still running
            if (cancelToken) {
                cancelToken.cancel('Request canceled');
            }

            const newCancelToken = axios.CancelToken.source();
            setCancelToken(newCancelToken);

            const res = await axiosClient.portal.post(
                '/api/analytics/getDonutData',
                {
                    ...props
                },
                { cancelToken: newCancelToken.token }
            );
            setDataSource(res.data);

            console.log(res.data);

            let tempDataSource = JSON.parse(JSON.stringify(res.data));
            setFilteredData(tempDataSource);
            setLoading(false);
        } catch (e: any) {
            if (axios.isCancel(e)) {
                // Cancel the previous request if it's still running
                // This message is shown when filters change quicker than backend has sent a response
                console.log('Request canceled:', e.message);
            } else {
                console.log(e);
                notification.error({ message: e.response.data.message });
            }
        }
    };

    // It's called everytime the values inside the array change (the array at the end, after the function)
    // Now that the array is empty, useEffect is called only once, at the start
    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            {filteredData != null && !loading && (
                <Pie
                    angleField={'metric'}
                    colorField={'dimension'}
                    data={filteredData.data}
                    appendPadding={[10, 150, 0, 0]}
                    radius={1}
                    innerRadius={0.64}
                    meta={{
                        metric: {
                            formatter: (value) => {
                                let currency = currencyMap[dataSource.currency];

                                let returnValue = ((value as number) || 0)?.toLocaleString('el-GR');
                                // Add currency symbol at the end of the value if needed
                                if (props.metric == 'Revenue') {
                                    returnValue = returnValue + (currency ? ' ' + currency : '');
                                }
                                return returnValue;
                            }
                        }
                    }}
                    width={500}
                    height={400}
                    padding={0}
                    label={{
                        type: 'inner',
                        offset: '-50%',
                        style: {
                            textAlign: 'center'
                        },
                        autoRotate: false,

                        formatter: (data, mappingData) => {
                            let currency = currencyMap[dataSource.currency];
                            let formNumber = new Intl.NumberFormat('en-US', { notation: 'compact' });
                            let returnValue;
                            // Add currency symbol at the end of the value if needed
                            if (props.metric == 'Revenue') {
                                returnValue = currency + ' ' + formNumber.format(+data.metric);
                            } else {
                                returnValue = formNumber.format(+data.metric);
                            }
                            return returnValue;
                        }
                    }}
                    statistic={{
                        title: {
                            offsetY: -4,
                            style: {
                                fontSize: '18px'
                            }
                        },
                        content: {
                            offsetY: 4,
                            style: {
                                fontSize: '32px'
                            }
                        }
                    }}
                    interactions={[
                        {
                            type: 'element-selected'
                        },
                        {
                            type: 'element-active'
                        },
                        {
                            type: 'pie-statistic-active'
                        }
                    ]}
                />
            )}

            {loading && <Spin />}
        </>
    );
};

export default DonutTabContent;
