import { SegmentedValue } from 'antd/es/segmented';
import dayjs from 'dayjs';
import { atom, useAtomValue } from 'jotai';
import { atomWithSuspenseQuery } from 'jotai-tanstack-query';
import axiosClient from '../api/axiosClient';
import { Models } from '../common/models';
import { useQuery } from 'react-query';
import qs from 'qs';
import {useState} from "react";

//
// Filters Type
//
export type FiltersType = {
    startDate: string;
    endDate: string;
    internalSuppliers: number[];
    externalSuppliers: number[];
    internalCustomers: number[];
    externalCustomers: number[];
    metricType: 'Dm' | 'Customer';
};

//
// Donut Props Types
//
export interface DonutPropsType {
    //
    // DICTIONARY
    //
    // Platform (5 tabs -> Youtube Channels, Youtube Labels, Spotify, ...)
    // Dimension (3 dimensions -> Artist/Video Title, Track/Channel Name, Country)
    // Metric (3 metrics -> customer revenue, dm revenue, views/streams)
    // Metric Type (2 metric types -> dm_revenue, customer_revenue) -> from general filters on top of Internal Dashboard's page
    platform?: string;
    metric?: string;
    dimension?: string;
    startDate: string;
    endDate: string;
    managed_customers: number[];
    metric_type: string;
}

export interface InvoicesDonutPropsType {
    //
    // DICTIONARY
    //
    // Dimension (2 dimensions -> project_name, customer_name)
    // Metric Type (2 metric types -> dm_revenue, customer_revenue) -> from general filters on top of Internal Dashboard's page
    dimension: string;
    startDate: string;
    endDate: string;
    internalSuppliers: number[];
    externalSuppliers: number[];
    internalCustomers: number[];
    externalCustomers: number[];
    metric_type: string;
}

export interface CallingTunesDonutPropsType {
    //
    // DICTIONARY
    //
    // Dimension (2 dimensions -> artist, track)
    // Metric Type (2 metric types -> dm_revenue, customer_revenue) -> from general filters on top of Internal Dashboard's page
    dimension?: string;
    startDate: string;
    endDate: string;
    managed_customers: number[];
    metric_type: string;
    metric?: string;
}

export const selectedPodioIdAtom = atom<SegmentedValue>('');
export const formRefAtom = atom<any>(null);

export const customersAtom = atom<
    Array<{
        customer_id: number;
        customer_name: string;
    }>
>([]);

export const dateRangeAtom = atom<{
    start_date?: string;
    end_date?: string;
}>({});

//
// Initialize Filters
//
export const filtersAtom = atom<FiltersType>({
    startDate: dayjs()
        .year(dayjs().get('year') - 1)
        .format('YYYY-MM-01'),
    endDate: dayjs().format('YYYY-MM-01'),
    internalSuppliers: [],
    externalSuppliers: [],
    internalCustomers: [],
    externalCustomers: [],
    metricType: 'Dm'
});

export const submittedFiltersAtom = atom<FiltersType>({
    startDate: dayjs()
        .year(dayjs().get('year') - 1)
        .format('YYYY-MM-01'),
    endDate: dayjs().format('YYYY-MM-01'),
    internalSuppliers: [],
    externalSuppliers: [],
    internalCustomers: [],
    externalCustomers: [],
    metricType: 'Dm'
});

export const defaultFiltersAtom = atom<FiltersType>({
    startDate: dayjs()
        .year(dayjs().get('year') - 1)
        .format('YYYY-MM-01'),
    endDate: dayjs().format('YYYY-MM-01'),
    internalSuppliers: [],
    externalSuppliers: [],
    internalCustomers: [],
    externalCustomers: [],
    metricType: 'Dm'
});

//
// Initialize Donut Props
//
export const donutPropsAtom = atom<DonutPropsType>({
    startDate: dayjs()
        .year(dayjs().get('year') - 1)
        .format('YYYY-MM-01'),
    endDate: dayjs().format('YYYY-MM-01'),
    managed_customers: [],
    metric_type: 'Dm'
});

export const invoicesDonutPropsAtom = atom<InvoicesDonutPropsType>({
    startDate: dayjs()
        .year(dayjs().get('year') - 1)
        .format('YYYY-MM-01'),
    endDate: dayjs().format('YYYY-MM-01'),
    internalSuppliers: [],
    externalSuppliers: [],
    internalCustomers: [],
    externalCustomers: [],
    metric_type: 'Dm',
    dimension: 'project_name'
});

export const callingTunesDonutPropsAtom = atom<CallingTunesDonutPropsType>({
    startDate: dayjs()
        .year(dayjs().get('year') - 1)
        .format('YYYY-MM-01'),
    endDate: dayjs().format('YYYY-MM-01'),
    managed_customers: [],
    metric_type: 'Dm',
    dimension: 'artist',
    metric: 'revenue'
});

export const scopeAtom = atom<string>((get) => {
    const metricToScope = {
        Dm: 'outgoing',
        Customer: 'incoming'
    };
    const filters = useAtomValue(submittedFiltersAtom);
    return metricToScope[filters.metricType];
});

export const isInvoiceTableEnabledAtom = atom<boolean>(true);

export const projectsOnExecutionUnfilteredAtom = atomWithSuspenseQuery<Models.Brands.Project[]>((get) => ({
    queryKey: ['projects', 'onexecution'],
    queryFn: async () =>
        await axiosClient.brands
            .get(`/api/projects/onexecution`)
            .then(({ data }) => data)
            .catch((reason) => {
                console.error(reason);
                return [];
            })
    // {
    //     const response = await axiosClient.brands.get(`/api/projects/onexecution`);
    //     return response.data;
    // }
}));

export const useInvoicesIncomingAtomValue = (ids?: number[], since?: string, until?: string) => {
    const allInvoicesIncomingAtom = atomWithSuspenseQuery<Models.Brands.LoggedInvoiceIncoming[]>((get) => ({
        queryKey: ['invoices', 'incoming', 'global', { ids, since, until }],
        queryFn: async () =>
            await axiosClient.brands
                .get(`/api/invoices/incoming/`, {
                    params: {
                        'supplier_customer_id[]': ids,
                        since,
                        until
                    }
                })
                .then(({ data }) => data)
                .catch((reason) => {
                    console.error(reason);
                    return {
                        data: [],
                        currency: 43,
                        total: 0
                    };
                })
    }));
    return useAtomValue(allInvoicesIncomingAtom);
};

export const useInvoicesIncomingFilteredQuery = (ids?: number[], since?: string, until?: string) => {
    return useQuery({
        queryKey: ['invoices', 'incoming', 'global', { ids, since, until }],
        queryFn: async () =>
            await axiosClient.brands
                .get(`/api/invoices/incoming/`, {
                    params: {
                        supplier_customer_id: ids,
                        since,
                        until
                    },
                    paramsSerializer: (params) => {
                        return qs.stringify(params, { arrayFormat: 'brackets' });
                    }
                })
                .then(({ data }) => data)
                .catch((reason) => {
                    console.error(reason);
                    return {
                        data: [],
                        currency: 43,
                        total: 0
                    };
                })
    });
};

export const useInvoicesFilteredQuery = (filters: FiltersType) => {
    // const filters = useAtomValue(filtersAtom);
    // const submittedFilters = useAtomValue(submittedFiltersAtom);
    // const scope = useAtomValue(scopeAtom);

    const scope = filters.metricType === 'Dm' ? 'outgoing' : 'incoming';
    const portal_route = filters.metricType === 'Dm' ? '' : 'portal';

    let params: {
        date_issued_start: string;
        month_issued_end: string;
        supplier_customer_id?: number[];
        supplier_external_id?: number[];
        accounting_customer_id?: number[];
        external_buyer_id?: number[];
    } = {
        date_issued_start: filters.startDate,
        month_issued_end: filters.endDate,
        supplier_customer_id: filters.internalSuppliers,
        supplier_external_id: filters.externalSuppliers
    };

    if (scope === 'outgoing') {
        params = {
            date_issued_start: filters.startDate,
            month_issued_end: filters.endDate,
            accounting_customer_id: filters.internalCustomers,
            external_buyer_id: filters.externalCustomers
        };
    }

    return useQuery({
        queryKey: ['invoices', scope, 'global', params],
        queryFn: async () =>
            await axiosClient.brands
                .get(`/api/invoices/${scope}/${portal_route}`, {
                    params,
                    paramsSerializer: (params) => {
                        return qs.stringify(params, { arrayFormat: 'brackets' });
                    }
                })
                .then(({ data }) => data)
                .catch((reason) => console.error(reason)),
        suspense: true,
        cacheTime: 0,
        staleTime: 0
    });
};