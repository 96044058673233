import React, {Suspense, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {Alert, ConfigProvider, Empty, notification, Radio, Row, Space, Spin, Tabs, TabsProps, Typography} from 'antd';
import {
    PageContainer,
    ProForm,
    ProFormDateRangePicker,
    ProFormGroup,
    ProFormInstance,
    ProFormRadio,
    ProFormSelect
} from '@ant-design/pro-components';
import enUSIntl from 'antd/es/locale/en_US';
import dayjs from 'dayjs';
import {useAtom} from 'jotai';
import axios, {CancelTokenSource} from 'axios';
import axiosClient from '../api/axiosClient';
import InternalDonutTabContent from './InternalDonutTabContent';
import {each, groupBy} from '@antv/util';
import {GlobalStateContext} from '../context/GlobalContext';
import {
    customersAtom,
    dateRangeAtom,
    filtersAtom,
    formRefAtom,
    isInvoiceTableEnabledAtom,
    submittedFiltersAtom
} from '../atoms/externalDashboardAtoms';
import useCurrencyMap from '../query_hooks/useCurrencyMap';
import ColumnGraph from './DashboardComponents/ColumnGraph';
import InvoiceDonutChart from './DashboardComponents/InvoiceDonutChart';
import TotalRevenuesPieMemo from './DashboardComponents/TotalRevenuesPie';
import BrandsInvoicesListExternal from './DashboardComponents/InvoiceTableExternal';
import DashboardLoading from './DashboardLoading';
import ExtensiveCustomerDonutData from "./ExtensiveCustomerDonutData";
import ExtensiveDonutData from "./ExtensiveDonutData";
import DonutTabContent from "./TabDonutContent";

// Tabs
export type TabsType = {
    name: string;
    metrics: string[];
    dimensions: string[];
    customer_id: string;
};
// Channel
export type RevenuesByChannelDataType = {
    asset: string;
    revenue: number;
};
export type RevenuesByChannelType = {
    currency: number;
    data: RevenuesByChannelDataType[];
    total: number;
};
// Label
export type RevenuesByLabelDataType = {
    asset: string;
    revenue: number;
};
export type RevenuesByLabelType = {
    currency: number;
    data: RevenuesByLabelDataType[];
    total: number;
};
// Product
export type RevenuesByProductDataType = {
    product: string;
    revenue: number;
};
export type RevenuesByProductType = {
    currency: number;
    data: RevenuesByProductDataType[];
    total: number;
};
// Owner
export type RevenuesByOwnerDataType = {
    owner: string;
    revenue: number;
};
export type RevenuesByOwnerType = {
    currency: number;
    data: RevenuesByOwnerDataType[];
    total: number;
};
// Invoices by Project
export type InvoicesByProjectDataType = {
    project_name: string;
    revenue: number;
};
export type InvoicesByProjectType = {
    currency: number;
    data: InvoicesByProjectDataType[];
    total: number;
};

// Invoices By Customer
export type InvoicesByCustomerDataType = {
    customer_name: string;
    revenue: number;
};

export type InvoicesByCustomerType = {
    currency: number;
    data: InvoicesByCustomerDataType[];
    total: number;
};
// DataSource
export type DataSourceType = {
    tabs: TabsType[];
    totalOwnedRevenuesByChannel: RevenuesByChannelType;
    totalOwnedRevenuesByLabel: RevenuesByLabelType;
    totalOwnedRevenuesByCallingTune: RevenuesByChannelType;
    totalOwnedRevenuesByMusicPublishing: RevenuesByChannelType;
    totalOwnedRevenuesByProduct: RevenuesByProductType;
    totalRevenuesByOwner: RevenuesByOwnerType;
    totalInvoicesByProject: InvoicesByProjectType;
    totalInvoicesByCustomer: InvoicesByCustomerType;
};

// DataSource
export type PlatformMetrics = {
    metric: string;
    dimension: string;
};

type Group = 'IS' | 'ES' | 'IC' | 'EC';
type Metric = 'Dm' | 'Customer';
type ColumnTabsType =
    | 'totalRevenuesByOwner'
    | 'totalOwnedRevenuesByLabel'
    | 'totalOwnedRevenuesByChannel'
    | 'totalOwnedRevenuesByCallingTune'
    | 'totalOwnedRevenuesByMusicPublishing'
    | 'totalOwnedRevenuesByProduct'
    | 'totalInvoicesByProject'
    | 'totalInvoicesByCustomer'
    | 'invoicesPendingTab';

interface FiltersType {
    startDate: string;
    endDate: string;
    customers: string[];
    internalSuppliers: number[];
    externalSuppliers: number[];
    internalCustomers: number[];
    externalCustomers: number[];
    metricType: Metric;
}

export interface DonutPropsType {
    platform?: string;
    metric?: string;
    dimension?: string;
    startDate: string;
    endDate: string;
    managed_customers: number[];
    metric_type: string;
}

export interface InvoicesDonutPropsType {
    dimension: string;
    startDate: string;
    endDate: string;
    internalSuppliers: number[];
    externalSuppliers: number[];
    internalCustomers: number[];
    externalCustomers: number[];
    metric_type: string;
}

// async function getInternalSuppliersPromise() {
//     return axiosClient.portal
//         .post('/api/employees/getmanagedcustomers')
//         .then((res) => ({
//             label: 'Internal Suppliers',
//             options: res.data
//                 ? res.data.map(({ customer_id, customer_name }: any) => ({
//                       label: `IS${customer_id} / ${customer_name}`,
//                       value: `IS::${customer_id}`
//                   }))
//                 : []
//         }))
//         .catch((e) => {
//             console.error(e);
//             return [];
//         });
// }

// async function getExternalSuppliersPromise() {
//     return axiosClient.brands
//         .get('/api/suppliers/')
//         .then(({data}) => ({
//             label: 'External Suppliers',
//             options: data.map((datum: any) => ({
//                 label: `ES${datum.id} / ${datum.name}`,
//                 value: `ES::${datum.id}`
//             }))
//         }))
//         .catch((e) => {
//             console.error(e);
//             return [];
//         });
// }
//
// async function getInternalCustomersPromise() {
//     return axiosClient.brands
//         .get(`/api/accounting_customers/`)
//         .then(({data}) => ({
//             label: 'Internal Customers',
//             options: data.map((datum: any) => ({
//                 label: `IC${datum.id} / ${datum.name}`,
//                 value: `IC::${datum.id}`
//             }))
//         }))
//         .catch((e) => {
//             console.error(e);
//             return [];
//         });
// }
//
// async function getExternalCustomersPromise() {
//     return axiosClient.brands
//         .get('/api/buyers/')
//         .then(({data}) => ({
//             label: 'External Customers',
//             options: data.map((datum: any) => ({
//                 label: `EC${datum.id} / ${datum.name}`,
//                 value: `EC::${datum.id}`
//             }))
//         }))
//         .catch((e) => {
//             console.error(e);
//             return [];
//         });
// }

function getCustomerArrayByGroup(values: Array<string>, filter: Group) {
    return values
        .map((cus) => cus.split('::'))
        .filter(([group, _]) => group === filter)
        .map(([_, value]) => +value);
}

const invoiceLabels = {
    Dm: 'Outgoing Invoices',
    Customer: 'Supplier (Customer) Invoices'
};

const defaultFilters: FiltersType = {
    startDate: dayjs()
        .year(dayjs().get('year') - 1)
        .format('YYYY-MM-01'),
    endDate: dayjs().format('YYYY-MM-01'),
    customers: [],
    internalSuppliers: [],
    externalSuppliers: [],
    internalCustomers: [],
    externalCustomers: [],
    metricType: 'Customer'
};

const Dashboard = () => {
    const {TabPane} = Tabs;
    const {data: currencyMap} = useCurrencyMap();

    const currDate = new Date();
    //
    // Initialize State
    //
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasInvoices, setHasInvoices] = useState<boolean>(false);
    const [invoicesPermissionPending, setInvoicesPermissionPending] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<DataSourceType>();
    const [donutChartMetrics, setDonutChartMetrics] = useState<Record<string, PlatformMetrics>>({});
    const [filters, setFilters] = useAtom(filtersAtom);
    const [submittedFilters, setSubmittedFilters] = useAtom(submittedFiltersAtom);
    const [invoicesDataSource, setInvoicesDataSource] = useState<RevenuesByOwnerType>({
        currency: 43,
        data: [],
        total: 0
    });
    // const [callingTunesDonutProps, setCallingTunesDonutProps] = useAtom(callingTunesDonutPropsAtom);
    // Todo maybe cancelToken is not necessary
    const [cancelToken, setCancelToken] = useState<CancelTokenSource | null>(null);
    const [annotations, setAnnotations] = useState<any>();

    const [donutProps, setDonutProps] = useState<DonutPropsType>({
        startDate: dayjs()
            .year(dayjs().get('year') - 1)
            .format('YYYY-MM-01'),
        endDate: dayjs().format('YYYY-MM-01'),
        managed_customers: [],
        metric_type: 'Dm'
    });

    const [invoicesDonutProps, setInvoicesDonutProps] = useState<InvoicesDonutPropsType>({
        startDate: dayjs()
            .year(dayjs().get('year') - 1)
            .format('YYYY-MM-01'),
        endDate: dayjs().format('YYYY-MM-01'),
        internalSuppliers: [],
        externalSuppliers: [],
        internalCustomers: [],
        externalCustomers: [],
        metric_type: 'Dm',
        dimension: 'project_name'
    });

    const [customers, setCustomersAtom] = useAtom(customersAtom);
    const [, setDateRange] = useAtom(dateRangeAtom);
    const [selectRef, setSelectRef] = useAtom(formRefAtom);
    const [, setInvoiceTableEnabled] = useAtom(isInvoiceTableEnabledAtom);
    setSelectRef(useRef<any>(null));
    const ref = useRef<ProFormInstance<any>>(null);

    const [selectedColumnTab, setSelectedColumnTab] = useState<ColumnTabsType>('totalRevenuesByOwner');

    //
    // Modal
    //
    const {isExtensiveCustomerModalVisible, setIsExtensiveCustomerModalVisible} = useContext(GlobalStateContext);

    const showModal = () => {
        setIsExtensiveCustomerModalVisible!(true);
    };

    useEffect(() => {
        setSelectedColumnTab('totalRevenuesByOwner');
        // console.log('data source: ', dataSource);
    }, [dataSource]);

    const getData = async () => {
        try {
            setIsLoading(true);

            // Cancel the previous request if it's still running
            if (cancelToken) {
                cancelToken.cancel('Request canceled');
            }

            const newCancelToken = axios.CancelToken.source();
            setCancelToken(newCancelToken);

            const availableIDs = await axiosClient.portal
                .get('/api/analytics/customerIDs')
                .then(({data}) => data);
            // console.log('available IDs: ', availableIDs);

            setSubmittedFilters((prev) => ({
                ...prev,
                customers: availableIDs.map((id: number) => `IS::${id}`),
                internalSuppliers: availableIDs,
                metricType: 'Customer',
                startDate: filters.startDate,
                endDate: filters.endDate
            }));

            const res = await axiosClient.portal.post(
                '/api/analytics/allChartData',
                {
                    startDate: filters.startDate,
                    endDate: filters.endDate
                },
                {cancelToken: newCancelToken.token}
            );

            let tempDataSource = JSON.parse(JSON.stringify(res.data));

            // console.log('temp data source: ', tempDataSource);

            let invoicesHeaderStatus: boolean = false;
            const invoices = await axiosClient.brands
                .get('/customer_api/invoices/monthly', {
                    params: {
                        month_start: filters.startDate,
                        month_end: filters.endDate
                    }
                })
                .then(({status, headers, data}) => {
                    if (status === 204) {
                        const invoicesHeaderResponse: boolean = headers['customer-has-invoices'];
                        // console.log('invoices header: ', invoicesHeaderResponse);
                        invoicesHeaderStatus = invoicesHeaderResponse;
                        return [];
                    }
                    return data ?? [];
                })
                .catch((reason) => {
                    console.error(reason);
                    return [];
                });

            // console.log(invoicesHeaderStatus);
            setInvoicesPermissionPending(invoicesHeaderStatus);

            if (invoices.length > 0) {
                setHasInvoices(true);
                const invoice_total: number = invoices.reduce(
                    (prev: number, curr: any) => prev + curr.revenue,
                    0
                );
                tempDataSource.totalRevenuesByOwner.total += invoice_total;
                const invoiceRevenuesObject: RevenuesByOwnerType = {
                    currency: dataSource ? dataSource.totalRevenuesByOwner.currency : 43,
                    data: invoices,
                    total: invoices.reduce(
                        (total: number, curr: RevenuesByOwnerDataType) => curr.revenue + total,
                        0
                    )
                };
                setInvoicesDataSource(invoiceRevenuesObject);
            }
            // console.log('invoices: ', invoices);

            tempDataSource.totalRevenuesByOwner.data.push(...invoices);

            // console.log('data source post invoices: ', tempDataSource);

            let initialDonutMetrics: any = {};
            res.data.tabs.map((t: any) => {
                initialDonutMetrics[t.name] = {};
                initialDonutMetrics[t.name]['metric'] = t.metrics[0];
                initialDonutMetrics[t.name]['dimension'] = t.dimensions[0];
            });
            initialDonutMetrics['Calling Tunes'] = {metric: 'Revenue', dimension: 'Artist'};
            initialDonutMetrics['Music Publishing'] = {metric: 'Revenue', dimension: 'Label'};

            // Set dataSource and donut chart values
            setDataSource(tempDataSource);
            setDonutChartMetrics(initialDonutMetrics);

            setDonutProps({
                startDate: filters.startDate,
                endDate: filters.endDate,
                platform: res.data.tabs[0].name,
                metric: initialDonutMetrics[res.data.tabs[0].name]['metric'],
                dimension: initialDonutMetrics[res.data.tabs[0].name]['dimension'],
                managed_customers: availableIDs,
                metric_type: 'Customer'
            });

            // setIsLoading(false)
        } catch (e: any) {
            if (axios.isCancel(e)) {
                // Cancel the previous request if it's still running
                // This message is shown when filters change quicker than backend has sent a response
                console.log('Request canceled:', e.message);
            } else {
                console.log(e);
                notification.error({message: e.response.data.message});
            }
        }
    };

    // useEffect(() => {
    //     console.log({donutProps})
    // }, [donutProps]);

    const formAnnotations = useCallback(() => {
        //
        // Annotations
        //
        const annotations: any = {};

        if (!currencyMap) return;

        if (dataSource) {
            let annotationsArr: any = [];

            // totalOwnedRevenuesByLabel
            if (dataSource.totalOwnedRevenuesByLabel) {
                each(groupBy(dataSource.totalOwnedRevenuesByLabel.data, 'month'), (values, k) => {
                    let value = values.reduce((a: any, b: { revenue: any }) => a + b.revenue, 0);

                    let finalValue = Math.round((value as number) * 100) / 100;
                    annotationsArr.push({
                        type: 'text',
                        position: [k, value],
                        content: `${
                            (finalValue.toLocaleString('el-GR') || 0) +
                            ' ' +
                            currencyMap[dataSource.totalOwnedRevenuesByLabel.currency]
                        }`,
                        style: {
                            textAlign: 'center',
                            fontSize: 14,
                            fill: 'rgba(0,0,0,0.85)'
                        },
                        offsetY: -15
                    });
                });
                annotations['totalOwnedRevenuesByLabel'] = annotationsArr;
            }

            // totalOwnedRevenuesByChannel
            if (dataSource.totalOwnedRevenuesByChannel) {
                annotationsArr = [];
                each(groupBy(dataSource.totalOwnedRevenuesByChannel.data, 'month'), (values, k) => {
                    let value = values.reduce((a: any, b: { revenue: any }) => a + b.revenue, 0);

                    let finalValue = Math.round((value as number) * 100) / 100;
                    annotationsArr.push({
                        type: 'text',
                        position: [k, value],
                        content: `${
                            (finalValue.toLocaleString('el-GR') || 0) +
                            ' ' +
                            currencyMap[dataSource.totalOwnedRevenuesByChannel.currency]
                        }`,
                        style: {
                            textAlign: 'center',
                            fontSize: 14,
                            fill: 'rgba(0,0,0,0.85)'
                        },
                        offsetY: -15
                    });
                });
                annotations['totalOwnedRevenuesByChannel'] = annotationsArr;
            }

            // totalOwnedRevenuesByCallingTune
            if (dataSource.totalOwnedRevenuesByCallingTune) {
                annotationsArr = [];
                each(groupBy(dataSource.totalOwnedRevenuesByCallingTune.data, 'month'), (values, k) => {
                    let value = values.reduce((a: any, b: { revenue: any }) => a + b.revenue, 0);

                    let finalValue = Math.round((value as number) * 100) / 100;
                    annotationsArr.push({
                        type: 'text',
                        position: [k, value],
                        content: `${
                            (finalValue.toLocaleString('el-GR') || 0) +
                            ' ' +
                            currencyMap[dataSource.totalOwnedRevenuesByCallingTune.currency]
                        }`,
                        style: {
                            textAlign: 'center',
                            fontSize: 14,
                            fill: 'rgba(0,0,0,0.85)'
                        },
                        offsetY: -15
                    });
                });
                annotations['totalOwnedRevenuesByCallingTune'] = annotationsArr;
            }

            // totalOwnedRevenuesByMusicPublishing
            if (dataSource.totalOwnedRevenuesByMusicPublishing) {
                annotationsArr = [];
                each(groupBy(dataSource.totalOwnedRevenuesByMusicPublishing.data, 'month'), (values, k) => {
                    let value = values.reduce((a: any, b: { revenue: any }) => a + b.revenue, 0);

                    let finalValue = Math.round((value as number) * 100) / 100;
                    annotationsArr.push({
                        type: 'text',
                        position: [k, value],
                        content: `${
                            (finalValue.toLocaleString('el-GR') || 0) +
                            ' ' +
                            currencyMap[dataSource.totalOwnedRevenuesByMusicPublishing.currency]
                        }`,
                        style: {
                            textAlign: 'center',
                            fontSize: 14,
                            fill: 'rgba(0,0,0,0.85)'
                        },
                        offsetY: -15
                    });
                });
                annotations['totalOwnedRevenuesByMusicPublishing'] = annotationsArr;
            }

            // totalOwnedRevenuesByProduct
            if (dataSource.totalOwnedRevenuesByProduct) {
                annotationsArr = [];
                each(groupBy(dataSource.totalOwnedRevenuesByProduct.data, 'month'), (values, k) => {
                    let value = values.reduce((a: any, b: { revenue: any }) => a + b.revenue, 0);

                    let finalValue = Math.round((value as number) * 100) / 100;
                    annotationsArr.push({
                        type: 'text',
                        position: [k, value],
                        content: `${
                            (finalValue.toLocaleString('el-GR') || 0) +
                            ' ' +
                            currencyMap[dataSource.totalOwnedRevenuesByProduct.currency]
                        }`,
                        style: {
                            textAlign: 'center',
                            fontSize: 14,
                            fill: 'rgba(0,0,0,0.85)'
                        },
                        offsetY: -15
                    });
                });
                annotations['totalOwnedRevenuesByProduct'] = annotationsArr;
            }

            // totalRevenuesByOwner
            if (dataSource.totalRevenuesByOwner) {
                //totalRevenuesByMonth
                annotationsArr = [];
                each(groupBy(dataSource.totalRevenuesByOwner.data, 'month'), (values, k) => {
                    let value = values.reduce((a: any, b: { revenue: any }) => a + b.revenue, 0);

                    let finalValue = Math.round((value as number) * 100) / 100;
                    annotationsArr.push({
                        type: 'text',
                        position: [k, value],
                        content: `${
                            (finalValue.toLocaleString('el-GR') || 0) +
                            ' ' +
                            currencyMap[dataSource.totalRevenuesByOwner.currency]
                        }`,
                        style: {
                            textAlign: 'center',
                            fontSize: 14,
                            fill: 'rgba(0,0,0,0.85)'
                        },
                        offsetY: -15
                    });
                });

                //set state
                annotations['totalRevenuesByOwner'] = annotationsArr;
            }

            // totalInvoicesByProject
            if (dataSource.totalInvoicesByProject) {
                annotationsArr = [];
                each(groupBy(dataSource.totalInvoicesByProject.data, 'month'), (values, k) => {
                    let value = values.reduce((a: any, b: { revenue: any }) => a + b.revenue, 0);

                    let finalValue = Math.round((value as number) * 100) / 100;
                    annotationsArr.push({
                        type: 'text',
                        position: [k, value],
                        content: `${
                            (finalValue.toLocaleString('el-GR') || 0) +
                            ' ' +
                            currencyMap[
                                dataSource?.totalInvoicesByProject?.currency
                                    ? dataSource.totalInvoicesByProject.currency
                                    : 43
                                ]
                        }`,
                        style: {
                            textAlign: 'center',
                            fontSize: 14,
                            fill: 'rgba(0,0,0,0.85)'
                        },
                        offsetY: -15
                    });
                });

                //set state
                annotations['totalInvoicesByProject'] = annotationsArr;
            }

            // totalInvoicesByCustomer
            if (dataSource.totalInvoicesByCustomer) {
                annotationsArr = [];
                each(groupBy(dataSource.totalInvoicesByCustomer.data, 'month'), (values, k) => {
                    let value = values.reduce((a: any, b: { revenue: any }) => a + b.revenue, 0);

                    let finalValue = Math.round((value as number) * 100) / 100;
                    annotationsArr.push({
                        type: 'text',
                        position: [k, value],
                        content: `${
                            (finalValue.toLocaleString('el-GR') || 0) +
                            ' ' +
                            currencyMap[
                                dataSource?.totalInvoicesByCustomer?.currency
                                    ? dataSource.totalInvoicesByCustomer.currency
                                    : 43
                                ]
                        }`,
                        style: {
                            textAlign: 'center',
                            fontSize: 14,
                            fill: 'rgba(0,0,0,0.85)'
                        },
                        offsetY: -15
                    });
                });

                //set state
                annotations['totalInvoicesByCustomer'] = annotationsArr;
            }
            setAnnotations(annotations);
        }

        setIsLoading(false);
    }, [dataSource, currencyMap]);

    const onRadioButtonChange = (value: any, product: string, metricOrDimension: string) => {
        setDonutChartMetrics((prevMetrics) => ({
            ...prevMetrics,
            [product]: {
                ...prevMetrics[product],
                [metricOrDimension]: value
            }
        }));

        setDonutProps((prevProps) => ({
            ...prevProps!,
            [metricOrDimension]: value
        }));
    };

    const items: TabsProps['items'] = [
        {
            key: 'totalRevenuesByOwner',
            label: `Net Revenue`,
            disabled: dataSource?.totalRevenuesByOwner.data.length === 0,
            children: (
                <ColumnGraph
                    dataSet={dataSource?.totalRevenuesByOwner}
                    annotations={annotations ? annotations['totalRevenuesByOwner'] : []}
                    statisticTitle='Total Revenue'
                    seriesField='owner'
                />
            )
        },
        {
            key: 'totalOwnedRevenuesByLabel',
            label: `Music Distribution`,
            disabled:
                isLoading ||
                dataSource?.totalOwnedRevenuesByLabel.data.length === 0 ||
                (submittedFilters.internalSuppliers.length === 0 &&
                    submittedFilters.internalCustomers.length !== 0),
            children: (
                <ColumnGraph
                    dataSet={dataSource?.totalOwnedRevenuesByLabel}
                    annotations={annotations ? annotations['totalOwnedRevenuesByLabel'] : []}
                    seriesField='asset'
                />
            )
        },
        {
            key: 'totalOwnedRevenuesByChannel',
            label: `YouTube`,
            // style: dataSource?.totalOwnedRevenuesByChannel.data.length == 0 ? {display: "none"} : {},
            disabled:
                isLoading ||
                dataSource?.totalOwnedRevenuesByChannel.data.length === 0 ||
                (submittedFilters.internalSuppliers.length === 0 &&
                    submittedFilters.internalCustomers.length !== 0),
            children: (
                <ColumnGraph
                    dataSet={dataSource?.totalOwnedRevenuesByChannel}
                    annotations={annotations ? annotations['totalOwnedRevenuesByChannel'] : []}
                    seriesField={'asset'}
                />
            )
        },
        {
            key: 'totalOwnedRevenuesByCallingTune',
            label: `Calling Tunes`,
            // style: dataSource?.totalOwnedRevenuesByChannel.data.length == 0 ? {display: "none"} : {},
            disabled:
                isLoading ||
                dataSource?.totalOwnedRevenuesByCallingTune.data.length === 0 ||
                (submittedFilters.internalSuppliers.length === 0 &&
                    submittedFilters.internalCustomers.length !== 0),
            children: (
                <ColumnGraph
                    dataSet={dataSource?.totalOwnedRevenuesByCallingTune}
                    annotations={annotations ? annotations['totalOwnedRevenuesByCallingTune'] : []}
                    seriesField={'asset'}
                />
            )
        },
        {
            key: 'totalOwnedRevenuesByMusicPublishing',
            label: `Music Publishing`,
            // style: dataSource?.totalOwnedRevenuesByChannel.data.length == 0 ? {display: "none"} : {},
            disabled:
                isLoading ||
                dataSource?.totalOwnedRevenuesByMusicPublishing.data.length === 0 ||
                (submittedFilters.internalSuppliers.length === 0 &&
                    submittedFilters.internalCustomers.length !== 0),
            children: (
                <ColumnGraph
                    dataSet={dataSource?.totalOwnedRevenuesByMusicPublishing}
                    annotations={annotations ? annotations['totalOwnedRevenuesByMusicPublishing'] : []}
                    seriesField={'asset'}
                />
            )
        },
        {
            key: 'totalOwnedRevenuesByProduct',
            label: `Platforms`,
            disabled:
                isLoading ||
                dataSource?.totalOwnedRevenuesByProduct.data.length === 0 ||
                (submittedFilters.internalSuppliers.length === 0 &&
                    submittedFilters.internalCustomers.length !== 0),
            children: (
                <ColumnGraph
                    dataSet={{
                        currency: dataSource?.totalOwnedRevenuesByProduct.currency,
                        data: dataSource?.totalOwnedRevenuesByProduct?.data.filter(i => i.product !== ''),
                        total: dataSource?.totalOwnedRevenuesByProduct.total
                    } as RevenuesByProductType}
                    annotations={annotations ? annotations['totalOwnedRevenuesByProduct'] : []}
                    seriesField={'product'}
                />
            )
        },
        // {
        //     key: 'totalInvoicesByProject',
        //     label: `Brand Deals`,
        //     disabled:
        //         dataSource?.totalInvoicesByProject?.data.length === 0 ||
        //         submittedFilters.metricType === 'Customer',
        //     children: (
        //         <ColumnGraph
        //             dataSet={dataSource?.totalInvoicesByProject}
        //             annotations={annotations ? annotations['totalInvoicesByProject'] : []}
        //             seriesField={'project_name'}
        //         />
        //     )
        // },
        // {
        //     key: 'totalInvoicesByCustomer',
        //     label: `Brand Deals`,
        //     disabled:
        //         dataSource?.totalInvoicesByCustomer?.data.length === 0 ||
        //         submittedFilters.metricType === 'Dm',
        //     children: (
        //         <ColumnGraph
        //             dataSet={dataSource?.totalInvoicesByCustomer}
        //             annotations={annotations ? annotations['totalInvoicesByCustomer'] : []}
        //             seriesField={'customer_name'}
        //         />
        //     )
        // }
        {
            key: 'totalInvoicesByProject',
            label: `Brand Deals`,
            disabled: isLoading || dataSource?.totalInvoicesByProject?.data.length === 0,
            children: (
                <ColumnGraph
                    dataSet={dataSource?.totalInvoicesByProject}
                    annotations={annotations ? annotations['totalInvoicesByProject'] : []}
                    seriesField={'project_name'}
                />
            )
        },
        {
            key: 'invoicesPendingTab',
            label: `Brand Deals Request`,
            disabled: true,
            children: (
                <div>
                    <p>This user has pending permission for invoices</p>
                </div>
            )
        }
    ].filter((item: any) => !item.disabled); // Filters disabled tabs
    // If disabled fixed to false for a tab, then this tab will always be visible
    // Remove filter to keep only the "disabled" logic for tabs with no data

    // It's called everytime the values inside the array change (the array at the end, after the function)
    // Now that the array is empty, useEffect is called only once, at the start
    useEffect(() => {
        // on mount
        setFilters(defaultFilters);
        setSubmittedFilters(defaultFilters);
        getData();

        // on unmount
        return () => {
            setFilters(defaultFilters);
            setSubmittedFilters(defaultFilters);
            // setCustomersAtom([]);
        };
    }, []);

    useEffect(() => {
        // Set Annotations
        formAnnotations();
    }, [dataSource]);

    useEffect(() => {
        setInvoicesDonutProps({
            internalSuppliers: submittedFilters.internalSuppliers,
            externalSuppliers: submittedFilters.externalSuppliers,
            internalCustomers: submittedFilters.internalCustomers,
            externalCustomers: submittedFilters.externalCustomers,
            metric_type: submittedFilters.metricType,
            dimension: 'project_name',
            startDate: filters.startDate,
            endDate: filters.endDate
        });
    }, [submittedFilters]);

    // useEffect(() => {
    //     axiosClient.brands
    //         .get('/customer_api/invoices/monthly', {
    //             params: {
    //                 month_start: submittedFilters.startDate,
    //                 month_end: submittedFilters.endDate
    //             }
    //         })
    //         .then((response) => console.log(response))
    //         .catch((reason) => console.error(reason));
    // }, []);

    if (isLoading) {
        return <DashboardLoading filters={submittedFilters} type='EXTERNAL'/>;
    }

    return (
        <PageContainer>
            <>
                <Row>
                    <ConfigProvider locale={enUSIntl}>
                        <ProForm<FiltersType>
                            layout={'inline'}
                            onValuesChange={(changedValues, values: any) => {
                                const is_group = getCustomerArrayByGroup(values.customers, 'IS');
                                const es_group = getCustomerArrayByGroup(values.customers, 'ES');
                                const ic_group = getCustomerArrayByGroup(values.customers, 'IC');
                                const ec_group = getCustomerArrayByGroup(values.customers, 'EC');
                                setFilters((prevFilters) => ({
                                    ...prevFilters,
                                    ...changedValues,
                                    internalSuppliers: is_group,
                                    externalSuppliers: es_group,
                                    internalCustomers: ic_group,
                                    externalCustomers: ec_group
                                }));
                            }}
                            initialValues={defaultFilters}
                            // submitter={false}
                            submitter={{
                                resetButtonProps: false,
                                submitButtonProps: {style: {marginLeft: 8}, disabled: isLoading}
                            }}
                            formRef={ref}
                            onFinish={async ({customers}) => {
                                setDateRange({
                                    start_date: filters.startDate,
                                    end_date: filters.endDate
                                });
                                // console.log('filters: ', filters);

                                // if (filters.metricType === 'Customer') {
                                //     const internalSuppliers = getCustomerArrayByGroup(customers, 'IS');

                                //     await axiosClient.portal
                                //         .post('/api/employees/getmanagedcustomers', internalSuppliers)
                                //         .then(({ data }) => setCustomersAtom(data))
                                //         .catch((e) => {
                                //             console.error(e);
                                //             setCustomersAtom([]);
                                //         });
                                // }
                                await getData();
                                setSubmittedFilters(filters);
                            }}
                        >
                            <Space align={'baseline'}>
                                <ProFormRadio.Group
                                    hidden
                                    name='metricType'
                                    label='Revenue'
                                    radioType={'button'}
                                    initialValue={'Customer'}
                                    options={[
                                        {
                                            label: 'DM',
                                            value: 'Dm'
                                        },
                                        {
                                            label: 'Customers',
                                            value: 'Customer'
                                        }
                                    ]}
                                />

                                <ProFormGroup>
                                    <ProFormSelect
                                        hidden
                                        name='customers'
                                        label='Select Customer'
                                        style={{width: '500px'}}
                                        // request={async () =>
                                        //     await Promise.all([
                                        //         getInternalSuppliersPromise()
                                        //         // getExternalSuppliersPromise(),
                                        //         // getInternalCustomersPromise(),
                                        //         // getExternalCustomersPromise()
                                        //     ]).catch((e) => {
                                        //         console.error(e);
                                        //         return [];
                                        //     })
                                        // }
                                        fieldProps={{
                                            mode: 'multiple',
                                            autoClearSearchValue: false,
                                            maxTagCount: 'responsive',
                                            onKeyDown: (e) => {
                                                if (e.code === 'Backspace') {
                                                    // console.log('backspace pressed');
                                                    // console.log(ref.current?.getFieldInstance("customers"))
                                                }
                                            }
                                        }}
                                    />
                                </ProFormGroup>

                                <ProFormDateRangePicker
                                    width='md'
                                    name='monthRange'
                                    initialValue={[
                                        currDate.getFullYear() -
                                        1 +
                                        '-' +
                                        (currDate.getMonth() + 1 < 10 ? '0' : '') +
                                        (currDate.getMonth() + 1) +
                                        '-01',
                                        currDate.getFullYear() +
                                        '-' +
                                        (currDate.getMonth() + 1 < 10 ? '0' : '') +
                                        (currDate.getMonth() + 1) +
                                        '-01'
                                    ]}
                                    fieldProps={{
                                        format: 'YYYY-MM',
                                        picker: 'month',
                                        placeholder: ['Start month', 'End month'],
                                        value: [
                                            dayjs(filters.startDate, 'YYYY-MM'),
                                            dayjs(filters.endDate, 'YYYY-MM')
                                        ]
                                    }}
                                    transform={(values: string[]) => {
                                        return {
                                            startDate: values[0].substring(0, 7) + '-01' || undefined,
                                            endDate: values[1].substring(0, 7) + '-01' || undefined
                                        };
                                    }}
                                    style={{flex: 1}}
                                />
                            </Space>
                        </ProForm>
                    </ConfigProvider>
                </Row>

                <br/>

                {/*//*/}
                {/*// If it stopped loading (backend has returned data) and dataSource has zero data, then notify client that his financial data are not found*/}
                {/*//*/}
                {!isLoading &&
                dataSource?.totalRevenuesByOwner.data.length === 0 &&
                dataSource?.totalOwnedRevenuesByLabel.data.length === 0 &&
                dataSource?.totalOwnedRevenuesByChannel.data.length === 0 &&
                dataSource?.totalOwnedRevenuesByCallingTune.data.length === 0 &&
                dataSource?.totalOwnedRevenuesByMusicPublishing.data.length === 0 &&
                dataSource?.totalOwnedRevenuesByProduct.data.length === 0 &&
                dataSource?.totalInvoicesByProject.data.length === 0 ? (
                    // dataSource?.totalInvoicesByCustomer.data.length === 0 ? (
                    <>
                        <br/>
                        <br/>
                        <Empty/>
                    </>
                ) : (
                    <>
                        <Tabs
                            onChange={(key) => {
                                // console.log({key});
                                setSelectedColumnTab(key as ColumnTabsType);

                                setInvoiceTableEnabled(
                                    key === 'totalRevenuesByOwner' ||
                                    key === 'totalInvoicesByProject' ||
                                    key === 'totalInvoicesByCustomer'
                                );

                                let donutTabKey = "YouTube (Labels)"
                                if (key === "totalOwnedRevenuesByChannel") {
                                    donutTabKey = "YouTube (Channels)"
                                } else if (key === "totalOwnedRevenuesByLabel") {
                                    for (let donutChartMetricsKey in donutChartMetrics) {
                                        if (donutChartMetricsKey != "YouTube (Channels)") {
                                            donutTabKey = donutChartMetricsKey
                                            break
                                        }
                                    }
                                } else if (key === "totalOwnedRevenuesByProduct") {
                                    for (let donutChartMetricsKey in donutChartMetrics) {
                                        donutTabKey = donutChartMetricsKey
                                        break
                                    }
                                } else if (key === "totalOwnedRevenuesByCallingTune") {
                                    donutTabKey = "Calling Tunes"
                                } else if (key === "totalOwnedRevenuesByMusicPublishing") {
                                    donutTabKey = "Music Publishing"
                                }

                                setDonutProps({
                                    ...donutProps!,
                                    platform: donutTabKey,
                                    metric: donutChartMetrics[donutTabKey]['metric'],
                                    dimension: donutChartMetrics[donutTabKey]['dimension']
                                })

                            }}
                            activeKey={selectedColumnTab}
                            defaultActiveKey='totalRevenuesByOwner'
                            items={items}
                        />

                        <br/>
                        <br/>

                        {/*{submittedFilters.internalCustomers.length > 0 &&*/}
                        {/*submittedFilters.internalSuppliers.length == 0 ? (*/}
                        {/*    submittedFilters.metricType === 'Customer' ? (*/}
                        {/*        <Empty />*/}
                        {/*    ) : null*/}
                        {/*) : (*/}

                        {selectedColumnTab === 'totalRevenuesByOwner' ? (
                            <TotalRevenuesPieMemo revenues={dataSource?.totalRevenuesByOwner}/>
                        ) : selectedColumnTab === 'totalInvoicesByProject' ||
                        selectedColumnTab === 'totalInvoicesByCustomer' ? (
                            <Tabs>
                                <TabPane tab={'Brand Deals'} key={'Brand Deals'}>
                                    <Row justify={'center'}>
                                        {invoicesDonutProps != null &&
                                            invoicesDonutProps.metric_type != null &&
                                            invoicesDonutProps.dimension != null && (
                                                <Suspense fallback={<Spin/>}>
                                                    <InvoiceDonutChart
                                                        {...invoicesDonutProps}
                                                        key={JSON.stringify(invoicesDonutProps)}
                                                    />
                                                </Suspense>
                                            )}
                                    </Row>
                                </TabPane>
                            </Tabs>
                        ) : selectedColumnTab === 'totalOwnedRevenuesByCallingTune' ? (
                            <Tabs>
                                <TabPane tab={'Calling Tunes'} key={'Calling Tunes'}>
                                    <Row justify={'space-between'}>
                                        {/*Metrics*/}
                                        <Radio.Group
                                            // value={'Revenue'}
                                            value={donutChartMetrics['Calling Tunes']?.metric}
                                            buttonStyle='solid'
                                            onChange={(e) =>
                                                onRadioButtonChange(
                                                    e.target.value,
                                                    'Calling Tunes',
                                                    'metric'
                                                )
                                            }
                                        >
                                            <Radio.Button value={'Revenue'} key={'Revenue'} defaultChecked>
                                                Revenue
                                            </Radio.Button>

                                            <Radio.Button value={'Times used'} key={'Times used'}>
                                                Times used
                                            </Radio.Button>

                                        </Radio.Group>

                                        {/*Dimensions*/}
                                        <Radio.Group
                                            // value={'Artist'}
                                            value={donutChartMetrics['Calling Tunes']?.dimension}
                                            buttonStyle='solid'
                                            onChange={(e) =>
                                                onRadioButtonChange(
                                                    e.target.value,
                                                    'Calling Tunes',
                                                    'dimension'
                                                )
                                            }
                                        >
                                            <Radio.Button value={'Artist'} key={'Artist'} defaultChecked>
                                                Artist
                                            </Radio.Button>
                                            <Radio.Button value={'Track'} key={'Track'}>
                                                Track
                                            </Radio.Button>

                                        </Radio.Group>
                                    </Row>

                                    <br/>
                                    <Row justify={'start'}>
                                        {donutProps != null &&
                                            donutProps.platform != null &&
                                            donutProps.metric != null &&
                                            donutProps.dimension != null && (
                                                <>
                                                    <ExtensiveCustomerDonutData
                                                        platform={donutProps.platform}
                                                        metric={donutProps.metric}
                                                        dimension={donutProps.dimension}
                                                        startDate={donutProps.startDate}
                                                        endDate={donutProps.endDate}
                                                        visible={isExtensiveCustomerModalVisible}
                                                        key={JSON.stringify(donutProps)}
                                                    />

                                                    <Alert
                                                        style={{textAlign: 'start'}}
                                                        message={
                                                            <>
                                                                <Typography.Text type={'secondary'}>
                                                                    {'For detailed view click '}
                                                                    <Typography.Link
                                                                        disabled={isLoading}
                                                                        onClick={() => {
                                                                            showModal();
                                                                        }}
                                                                    >
                                                                        here
                                                                    </Typography.Link>
                                                                    .
                                                                </Typography.Text>
                                                            </>
                                                        }
                                                        type={'info'}
                                                        showIcon={true}
                                                    />
                                                </>
                                            )}
                                    </Row>

                                    <Row justify={'center'}>
                                        {/*{callingTunesDonutProps != null &&*/}
                                        {/*    callingTunesDonutProps.metric_type != null &&*/}
                                        {/*    callingTunesDonutProps.dimension != null && (*/}
                                        {/*        <Suspense fallback={<Spin/>}>*/}
                                        {/*            <CallingTunesDonutChart*/}
                                        {/*                {...callingTunesDonutProps}*/}
                                        {/*                key={JSON.stringify(callingTunesDonutProps)}*/}
                                        {/*            />*/}
                                        {/*        </Suspense>*/}
                                        {/*    )}*/}
                                        {donutProps != null &&
                                            donutProps.platform != null &&
                                            donutProps.metric != null &&
                                            donutProps.dimension != null && (
                                                // <Suspense fallback={<Spin/>}>
                                                //     <CallingTunesDonutChart
                                                //         {...donutProps}
                                                //         key={JSON.stringify(donutProps)}
                                                //     />
                                                // </Suspense>
                                                <InternalDonutTabContent
                                                    {...donutProps}
                                                    key={JSON.stringify(donutProps)}
                                                />
                                            )}
                                    </Row>
                                </TabPane>
                            </Tabs>
                        ) : selectedColumnTab === 'totalOwnedRevenuesByMusicPublishing' ? (
                            <Tabs>
                                <TabPane tab={'Music Publishing'} key={'Music Publishing'}>
                                    <Row justify={'space-between'}>
                                        {/*Metrics*/}
                                        <Radio.Group
                                            // value={'Revenue'}
                                            value={donutChartMetrics['Music Publishing']?.metric}
                                            buttonStyle='solid'
                                            onChange={(e) =>
                                                onRadioButtonChange(
                                                    e.target.value,
                                                    'Music Publishing',
                                                    'metric'
                                                )
                                            }
                                        >
                                            <Radio.Button value={'Revenue'} key={'Revenue'} defaultChecked>
                                                Revenue
                                            </Radio.Button>

                                            <Radio.Button value={'Units'} key={'Units'}>
                                                Units
                                            </Radio.Button>

                                        </Radio.Group>

                                        {/*Dimensions*/}
                                        <Radio.Group
                                            // value={'Artist'}
                                            value={donutChartMetrics['Music Publishing']?.dimension}
                                            buttonStyle='solid'
                                            onChange={(e) =>
                                                onRadioButtonChange(
                                                    e.target.value,
                                                    'Music Publishing',
                                                    'dimension'
                                                )
                                            }
                                        >
                                            <Radio.Button value={'Label'} key={'Label'} defaultChecked>
                                                Label
                                            </Radio.Button>
                                            <Radio.Button value={'Song'} key={'Song'}>
                                                Song
                                            </Radio.Button>
                                            <Radio.Button value={'Country'} key={'Country'}>
                                                Country
                                            </Radio.Button>

                                        </Radio.Group>
                                    </Row>

                                    <br/>

                                    <Row justify={'start'}>
                                        {donutProps != null &&
                                            donutProps.platform != null &&
                                            donutProps.metric != null &&
                                            donutProps.dimension != null && (
                                                <>
                                                    <ExtensiveDonutData
                                                        platform={donutProps.platform}
                                                        metric={donutProps.metric}
                                                        dimension={donutProps.dimension}
                                                        startDate={donutProps.startDate}
                                                        endDate={donutProps.endDate}
                                                        managed_customers={
                                                            donutProps.managed_customers
                                                        }
                                                        metric_type={donutProps.metric_type}
                                                        visible={isExtensiveCustomerModalVisible}
                                                        key={JSON.stringify(donutProps)}
                                                    />

                                                    <Alert
                                                        style={{textAlign: 'start'}}
                                                        message={
                                                            <>
                                                                <Typography.Text type={'secondary'}>
                                                                    {'For detailed view click '}
                                                                    <Typography.Link
                                                                        disabled={isLoading}
                                                                        onClick={() => {
                                                                            showModal();
                                                                        }}
                                                                    >
                                                                        here
                                                                    </Typography.Link>
                                                                    .
                                                                </Typography.Text>
                                                            </>
                                                        }
                                                        type={'info'}
                                                        showIcon={true}
                                                    />
                                                </>
                                            )}
                                    </Row>

                                    <Row justify={'center'}>
                                        {donutProps != null &&
                                            donutProps.platform != null &&
                                            donutProps.metric != null &&
                                            donutProps.dimension != null && (
                                                <DonutTabContent
                                                    {...donutProps}
                                                    key={JSON.stringify(donutProps)}
                                                />
                                            )}
                                    </Row>
                                </TabPane>
                            </Tabs>
                            ) : (
                            <Tabs
                                onChange={(key) => {
                                    // console.log({key});
                                    setDonutProps({
                                        ...donutProps!,
                                        platform: key,
                                        metric: donutChartMetrics[key]['metric'],
                                        dimension: donutChartMetrics[key]['dimension']
                                    });
                                }}
                            >
                                {dataSource?.tabs
                                    .filter((tab) => {
                                        if (selectedColumnTab == 'totalOwnedRevenuesByLabel') {
                                            return (
                                                tab.name === 'YouTube (Labels)' ||
                                                tab.name === 'Spotify' ||
                                                tab.name === 'Apple Music' ||
                                                tab.name === 'iTunes' ||
                                                tab.name === 'Amazon Premium'
                                            );
                                        }

                                        if (selectedColumnTab == 'totalOwnedRevenuesByChannel') {
                                            return tab.name === 'YouTube (Channels)';
                                        }
                                        //
                                        // if (selectedColumnTab == 'totalOwnedRevenuesByCallingTune') {
                                        //     return tab.name === 'Calling Tunes';
                                        // }

                                        // if (
                                        //     selectedColumnTab === 'totalInvoicesByProject' ||
                                        //     selectedColumnTab === 'totalInvoicesByCustomer'
                                        // ) {
                                        //     return false;
                                        // }

                                        return true;
                                    })
                                    .map((tab: TabsType) => (
                                        <TabPane tab={tab.name} key={tab.name}>
                                            <Row justify={'space-between'}>
                                                <Radio.Group
                                                    value={donutChartMetrics[tab.name]?.metric}
                                                    buttonStyle='solid'
                                                    onChange={(e) =>
                                                        onRadioButtonChange(
                                                            e.target.value,
                                                            tab.name,
                                                            'metric'
                                                        )
                                                    }
                                                    // defaultValue={tab.metrics[0]}
                                                >
                                                    {tab.metrics.map((metric: any) => (
                                                        <Radio.Button value={metric} key={metric}>
                                                            {metric}
                                                        </Radio.Button>
                                                    ))}
                                                </Radio.Group>

                                                <Radio.Group
                                                    value={donutChartMetrics[tab.name]?.dimension}
                                                    buttonStyle='solid'
                                                    onChange={(e) =>
                                                        onRadioButtonChange(
                                                            e.target.value,
                                                            tab.name,
                                                            'dimension'
                                                        )
                                                    }
                                                    // defaultValue={tab.dimensions[0]}
                                                >
                                                    {tab.dimensions.map((dimension: any) => (
                                                        <Radio.Button value={dimension} key={dimension}>
                                                            {dimension}
                                                        </Radio.Button>
                                                    ))}
                                                </Radio.Group>
                                            </Row>

                                            <br/>

                                            <Row justify={'start'}>
                                                {donutProps != null &&
                                                    donutProps.platform != null &&
                                                    donutProps.metric != null &&
                                                    donutProps.dimension != null && (
                                                        <>
                                                            <ExtensiveCustomerDonutData
                                                                platform={donutProps.platform}
                                                                metric={donutProps.metric}
                                                                dimension={donutProps.dimension}
                                                                startDate={donutProps.startDate}
                                                                endDate={donutProps.endDate}
                                                                visible={isExtensiveCustomerModalVisible}
                                                                key={JSON.stringify(donutProps)}
                                                            />

                                                            <Alert
                                                                style={{textAlign: 'start'}}
                                                                message={
                                                                    <>
                                                                        <Typography.Text type={'secondary'}>
                                                                            {'For detailed view click '}
                                                                            <Typography.Link
                                                                                disabled={isLoading}
                                                                                onClick={() => {
                                                                                    showModal();
                                                                                }}
                                                                            >
                                                                                here
                                                                            </Typography.Link>
                                                                            .
                                                                        </Typography.Text>
                                                                    </>
                                                                }
                                                                type={'info'}
                                                                showIcon={true}
                                                            />
                                                        </>
                                                    )}
                                            </Row>

                                            <Row justify={'center'}>
                                                {/*!!!!!!!!!! SOS TIP !!!!!!!!!!*/}
                                                {/*WHEN YOU RENDER A COMPONENT (CLASS) INSIDE .map() FUNCTION, ALWAYS USE "key" PROP!!!!!!!!!*/}
                                                {donutProps != null &&
                                                    donutProps.platform != null &&
                                                    donutProps.metric != null &&
                                                    donutProps.dimension != null && (
                                                        <DonutTabContent
                                                            {...donutProps}
                                                            key={JSON.stringify(donutProps)}
                                                        />
                                                    )}
                                            </Row>
                                        </TabPane>
                                    ))}
                            </Tabs>
                        )}
                    </>
                )}
                <br/>
                {dataSource && dataSource.totalInvoicesByProject.total !== 0 && (
                    <Suspense fallback={<Spin/>}>
                        <div
                            style={{
                                marginLeft: '1%',
                                marginRight: '1%',
                                marginTop: '2%',
                                marginBottom: '5%'
                            }}
                        >
                            <BrandsInvoicesListExternal filters={submittedFilters}/>
                            {/* <BrandsInvoicesList
                                filters={{
                                    customers: ['IS::3776'],
                                    startDate: filters.startDate,
                                    endDate: filters.endDate,
                                    internalSuppliers: [3776],
                                    internalCustomers: [],
                                    externalCustomers: [],
                                    externalSuppliers: [],
                                    metricType: 'Customer'
                                }}
                            /> */}
                        </div>
                    </Suspense>
                )}
            </>
        </PageContainer>
    );
};

export default Dashboard;
